import {defineStore} from 'pinia';
import { useStorage } from '@vueuse/core';

import { HistoryEvent } from "@/sdk";

export interface StoreItem {
        name: string,
        description: string,
        credits: number,
        discount: number,
        sku: string,
        unit_amount: StorePrice,
        quantity: number,
    }

export interface StorePrice {
        currency_code: string,
        value: string,
}

const itemDefs = [
    {
        name: "1",
        description: "1 Single Credits",
        sku: "Memory-Tree-1",
        credits: 1,
        discount: 0,
        unit_amount: {
            currency_code: "USD",
            value: "4.00",
        } as StorePrice,
        quantity: 0,
    } as StoreItem,
    {
        name: "10",
        description: "10 Credit Pack",
        sku: "Memory-Tree-10",
        credits: 10,
        discount: 0,
        unit_amount: {
            currency_code: "USD",
            value: "30.00",
        } as StorePrice,
        quantity: 0,
    } as StoreItem,
    {
        name: "25",
        description: "25 Credit Pack",
        sku: "Memory-Tree-25",
        credits: 25,
        discount: 0,
        unit_amount: {
            currency_code: "USD",
            value: "75.00",
        } as StorePrice,
        quantity: 0,
    } as StoreItem,
    {
        name: "50",
        description: "50 Credit Pack",
        sku: "Memory-Tree-50",
        credits: 50,
        discount: 5,
        unit_amount: {
            currency_code: "USD",
            value: "150.00",
        } as StorePrice,
        quantity: 0,
    } as StoreItem,
    {
        name: "100",
        description: "100 Credit Pack",
        sku: "Memory-Tree-100",
        credits: 100,
        discount: 10,
        unit_amount: {
            currency_code: "USD",
            value: "300.00",
        } as StorePrice,
        quantity: 0,
    } as StoreItem,
    {
        name: "250",
        description: "250 Credit Pack",
        sku: "Memory-Tree-250",
        credits: 250,
        discount: 20,
        unit_amount: {
            currency_code: "USD",
            value: "750.00",
        } as StorePrice,
        quantity: 0,
    } as StoreItem,
    {
        name: "500",
        description: "500 Credit Pack",
        sku: "Memory-Tree-500",
        credits: 500,
        discount: 30,
        unit_amount: {
            currency_code: "USD",
            value: "1500.00",
        } as StorePrice,
        quantity: 0,
    } as StoreItem,
    {
        name: "1000",
        description: "1000 Credit Pack",
        sku: "Memory-Tree-1000",
        credits: 1000,
        discount: 40,
        unit_amount: {
            currency_code: "USD",
            value: "3000.00",
        } as StorePrice,
        quantity: 0,
    }
] as StoreItem[];

export const useTokenStore = defineStore('tokens', {
    state: () => {
        return { 
            loading: true,
            items: useStorage('items', itemDefs),
            }
    },
    actions: {
        clear() {
            this.items.forEach(item => item.quantity = 0);    
        },
        resetStore() {
            this.clear();
            this.items = itemDefs;
            //this.$reset();
        },
    },
    getters: {        
        totalCartCredits: (state) => state.items.reduce((previousValue: number, itemB: StoreItem) => { 
            return previousValue + (itemB.credits * itemB.quantity);
        }, 0),
        totalItems: (state) => {
            let total = 0;
            for (let iTotal = 0; iTotal < state.items.length; iTotal++) {
              total = total + state.items[iTotal].quantity;
            }
            return total;
          }

    }
});

