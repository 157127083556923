export const productKey =  import.meta.env.VITE_PRODUCT;  // "fiesta-gatitos";


import memoryTreeDef from "@/assets/memory-tree/product.json";
import fiestaGatitosDef from "@/assets/fiesta-gatitos/product.json";
import vjLoopaDef from "@/assets/vjloopa/product.json";

const products = {
  "memory-tree": memoryTreeDef,
  "vjloopa": vjLoopaDef,
  "fiesta-gatitos": fiestaGatitosDef,
} as any


import memoryTreeSequence from '@/assets/memory-tree/sequence.json';
import vjLoopaSequence from '@/assets/vjloopa/sequence.json';
import fiestaGatitosSequence from '@/assets/fiesta-gatitos/sequence.json';

const sequences = {
  "memory-tree": memoryTreeSequence,
  "vjloopa": vjLoopaSequence,
  "fiesta-gatitos": fiestaGatitosSequence,  
}

export const product = products[productKey];

export const sequence = (sequences as any)[productKey];

//export const productDef = import("@/assets/" + import.meta.env.VITE_PRODUCT + "/product.json");

export function getProductPath (path: string) {
    return "/products/" + productKey + path;
  }