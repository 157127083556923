<template>
  <!-- Fade Footer -->
  <ion-footer collapse="condense" :translucent="true" class="page-message" v-if="isVisible">

    <ion-toolbar color="warning" v-if="unregisteredWithJobStarted">
      <ion-buttons  class="ion-padding ion-justify-content-center">        

        <ion-grid>
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">

              <ion-text class="ion-margin-right lead">
                ⚠️
                DON'T LOSE YOUR {{ videoTermPluralized }}! &nbsp;&nbsp;&nbsp;&nbsp;
              </ion-text>      

            </ion-col>

            <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">

                <ion-item>
                  <!-- <ion-label position="floating" color="medium"></ion-label> -->
                  <ion-input type="email" required placeholder="Enter your e-mail@address..." 
                        v-model="form.email"                        
                        @keyup.enter="setEmail"
                        ></ion-input>
                  <ion-button color="light" fill="solid" size="large" slot="end" @click="setEmail" :disabled="processing">
                    <ion-spinner v-if="processing" color="dark"></ion-spinner>
                    <span v-if="!processing">
                      <ion-icon :icon="saveOutline"></ion-icon>
                      Save
                    </span>
                  </ion-button>            
                  
                  
                  <ion-icon :icon="checkmarkCircle" v-if="v$.$dirty && v$.email.$errors.length == 0" slot="start" color="success"></ion-icon>
                  <ion-icon :icon="closeCircle" v-if="v$.email.$errors.length" slot="start" color="danger"></ion-icon>
                  <ion-note slot="helper" v-if="v$.email.$errors.length" color="dark">

                      <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                          <div class="error-msg">{{ error.$message }}</div>
                      </div>    
                      <div class="input-errors">
                          <div class="error-msg">Enter a valid e-mail address</div>
                      </div>
                      
                  </ion-note>
                  <ion-note slot="error" v-if="v$.email.$errors.length">Invalid e-mail</ion-note>    

                </ion-item>
      
            </ion-col>
          </ion-row>
        </ion-grid>   
        
        <ion-button @click="minView">
          <ion-icon :icon="close"></ion-icon>
        </ion-button>
        
      </ion-buttons>
    </ion-toolbar>
  
  </ion-footer>
</template>

<script setup lang="ts">
import { menuController, useIonRouter } from "@ionic/vue";
import { computed, ref, inject, watch, reactive } from "vue";
import { home } from "ionicons/icons";
import LanguageSelector from "@/components/LanguageSelector.vue";

import { menu, cartOutline, close, caretForwardOutline, saveOutline, checkmarkCircle, closeCircle } from "ionicons/icons";

import {useTokenStore, StoreItem} from "@/store/tokenStore";

import {useUserProfileStore} from "@/store/userProfileStore";
import {useActiveJobStore} from "@/store/activeJobStore";
import {useJobStore} from "@/store/jobStore";

import useVuelidate, { ErrorObject } from '@vuelidate/core';
import { required, email, helpers  } from '@vuelidate/validators';

import { confirm, checkForExistingUser } from '@/utility';
import { errorHandler } from '@/sdk';


const userProfile = useUserProfileStore();
const activeJob = useActiveJobStore();
const jobs = useJobStore();
const tokens = useTokenStore();

const props = defineProps({
  title: {
    type: String,
  },
  showBackButton: {
    type: Boolean,
    default: false,
  },
});

const itemsFiltered = computed(() => {
  return tokens.items.filter((item: any) => item.quantity > 0);
});

const unregisteredWithJobStarted = computed(() => {
  if (userProfile.isTemporaryUser && jobs.all?.total > 0) return true;
  return false;
});

const videoTermPluralized = computed(() => {
    const plural = jobs.all?.total > 1 ? "S" : "";
    return 'VIDEO' + plural; 
  });





const processing = ref(false);

const router = useIonRouter();


async function setEmail() {

    v$.value.$validate().then((valid) => {
    if (!valid) return;        

    processing.value = true;

    console.log("SET EMAIL",form.email);
    const tempPW = `Temp-${userProfile.userId}`;

    userProfile.updateEmail(form.email, tempPW)   
      .then(
        async () => {
            console.log("SUCCESS");
            //router.replace("/videos");
            await userProfile.startup();

            //#WELCOME
            //TODO: Send the welcome e-mail and issue the account 2 welcome credits 
            userProfile.sendWelcomeEmail();

            if (userProfile.registered) {
              router.replace("/profile/security?welcome");
            }
        }
    )
    .catch(async (err: Error) => {
      if (await checkForExistingUser(form.email, err)) {

        // console.log("ADDING ERROR TO V$");
        // //No further action here...
        // v$.value.$errors.push({
        //         //scope: 'DONT USE SCOPE IF YOU DONT HAVE'
        //         $property: 'email',
        //         $message: 'e-mail address is already in use'
        //       } as ErrorObject);        

      } else {

        const errorObj = {
                //scope: 'DONT USE SCOPE IF YOU DONT HAVE'
                $property: 'email',
                $message: err.message
              } as ErrorObject;

        console.log("ADDING ERROR TO V$ 2",errorObj);
        v$.value.email.$errors.push(errorObj);               

      }
    })
    .finally(()=>processing.value = false);
    
});

}

const form = reactive({
    email: "",
});


const rules = {
    email: { required, email },    
}

const v$ = useVuelidate(rules, form);

//TODO: Watch the page scroll, and collapse the message accordingly.'

const isVisible = ref(true);

function minView () {

  isVisible.value = false;  

}

</script>

<style scoped>

ion-toolbar {
  --background: transparent;
  border: none;
}


.lead {
  font-weight: 900;
  font-size: 2em;
}

/* ion-footer.page-message {position: absolute; bottom: 0;} */

ion-note[color="dark"] {
  color: #000000;
}

@media screen and (max-width: 700px) {
  
  .lead {    
    font-size: 1.2em;
  }
  
}

</style>
