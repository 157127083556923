import { toastController, alertController } from "@ionic/vue";

import { videoTypes, imageTypes } from "@/sdk";
import { Models } from "appwrite";

import exifr from 'exifr';
import { useUserProfileStore } from "./store/userProfileStore";

import {SourceMetaData } from "@/sdk";

import router from "@/router";


export const confirm = async (message = "Are you sure?", header = "Confirm Alert", subHeader = "Let's confirm"): Promise<boolean> => {

    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>(resolve => {
        resolveFunction = resolve;
      });

    const myAlert = await alertController.create({
      header,
      subHeader,
      message,
      buttons: [
        {
          text: 'Yes',
          handler: () => {
            console.log('ANSWER: YES');
            resolveFunction(true);
          }
        },
        {
          text: 'No',
          handler: () => {
            console.log('ANSWER: NO');
            resolveFunction(false);
          }
        }
      ]
    });
    await myAlert.present();

    return promise;
  }

  export const alerta = async (message = "Nice!", header = "Alert", subHeader = ""): Promise<boolean> => {

    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>(resolve => {
        resolveFunction = resolve;
      });

    const myAlert = await alertController.create({
      header,
      subHeader,
      message,
      buttons: [
        {
          text: 'Close',
          handler: () => {
            console.log('ANSWER: Close');
            resolveFunction(true);
          }
        },
        // {
        //   text: 'No',
        //   handler: () => {
        //     console.log('ANSWER: NO');
        //     resolveFunction(false);
        //   }
        // }
      ]
    });
    await myAlert.present();

    return promise;
  }

  //imageOrientation(URL.createObjectURL(files[x]));

  export const imageDimensions = (file: string) => {

    return new Promise( (resolve, reject) => {
      var image = new Image();
      image.src = file;
      image.onload = () => resolve({height: image.naturalHeight, width: image.naturalWidth});
      image.onerror = () => reject(new Error('could not load image'));
    });
  }

  export const videoDimensions = (file: string) => {
    return new Promise( (resolve, reject) => {
      const $video = document.createElement("video");
      $video.src = file;
      $video.onloadedmetadata = () => resolve({height: $video.videoHeight, width: $video.videoWidth});
      $video.onerror = () => reject(new Error('could not load image'));
    });
  }


  export const loadExif = async (file: string) => {

          //NOTE: First see if we can get the dimensions from the EXIF data...
          let exifData;
          try {
            exifData = await exifr.parse(file, {translateKeys: true, translateValues: false}); //, pick: ['ExifImageWidth', 'ExifImageHeight', 'Orientation', 'DateTimeOriginal']
            console.log('Parsed exifData', exifData);
          } catch (e) {
            console.log('ERROR: Parsing exifData failed', e);
            return;
          }

          if (!exifData) {
            console.log(`WARNING: Could not load EXIF for ${file}`);
            return;
          }

          //alert(JSON.stringify(exifData));
      
          const captureDate = exifData?.DateTimeOriginal;
          let dimensions = { width: exifData?.ExifImageWidth ?? exifData?.ImageWidth, height: exifData.ExifImageHeight ?? exifData?.ImageHeight }

          const orientation = exifData.Orientation;
          if (orientation) {
            /*
            1 = 0 degrees: the correct orientation, no adjustment is required.
            2 = 0 degrees, mirrored: image has been flipped back-to-front.
            3 = 180 degrees: image is upside down.
            4 = 180 degrees, mirrored: image has been flipped back-to-front and is upside down.
            5 = 90 degrees: image has been flipped back-to-front and is on its side.
            6 = 90 degrees, mirrored: image is on its side.
            7 = 270 degrees: image has been flipped back-to-front and is on its far side.
            8 = 270 degrees, mirrored: image is on its far side.
            */
            console.log('Exif orientation:', orientation);
            if (orientation > 4) {
              //NOTE: Portrait 
              dimensions = { height: exifData?.ExifImageWidth ?? exifData?.ImageWidth, width: exifData.ExifImageHeight ?? exifData?.ImageHeight }
            } 
          }
      
          console.log("LOADEXIF", file, { captureDate , dimensions });

          return { captureDate , dimensions }
    
  }


  export const getSourceOrientation = async (file: string, storageRecord: Models.File, dimensions = {width:0,height:0}) => {     

    if (!dimensions.height && !dimensions.width) {
      if (Object.values(imageTypes).includes(storageRecord.mimeType)) {
          dimensions = await imageDimensions(file) as any;
      } 
      if (Object.values(videoTypes).includes(storageRecord.mimeType)) {
          dimensions = await videoDimensions(file) as any;
      }       
    }

    //await videoOrientation(URL.createObjectURL(files[x]));

    let orientation = "ordinal";
    if (dimensions.width > dimensions.height) {
        orientation = "landscape";
    }
    if (dimensions.height > dimensions.width) {
        orientation = "portrait";
    }
    if (dimensions.width == dimensions.height) {
      orientation = "landscape";
    }

    console.log(file,"ORIENTATION", orientation, dimensions.width, dimensions.height);

    return orientation;

}



export const getCaptureDatetime = async (file: string | File, storageRecord: Models.File) => { 

  let exifData = await exifr.parse(file)
  console.log('exifData', exifData)

  const captureDate = exifData.DateTimeOriginal;

  console.log("CAPTURE DATE", captureDate);

  //console.log("FILENAME", (file as any).name);

  return captureDate;

}



  // const videoUrl = URL.createObjectURL(files[x]);
  // const $video = document.createElement("video");
  // $video.src = videoUrl;
  // $video.addEventListener("loadedmetadata", function () {
  //     console.log("Loaded video...");
  //     width = this.videoWidth
  //     height = this.videoHeight
  //     console.log("width:", this.videoWidth);
  //     console.log("height:", this.videoHeight);
  // });

  // const imgUrl = URL.createObjectURL(files[x]);
  // const $image = document.createElement("img");
  // $image.src = imgUrl;
  // $image.addEventListener("load", function () {
  //     console.log("Loaded img...");
  //     width = this.naturalWidth
  //     height = this.naturalHeight
  //     console.log("width:", this.naturalWidth);
  //     console.log("height:", this.naturalHeight);
  // });



// export function get_exif_data(image_result)
// 		{
// 			var data = image_result.replace("data:image/jpeg;base64,", "");
// 			var decoded_data = decode64(data);
			
// 			getLongAt = function(iOffset, bBigEndian) {
// 						var iByte1 = decoded_data.charCodeAt(iOffset),
// 							iByte2 = decoded_data.charCodeAt(iOffset + 1),
// 							iByte3 = decoded_data.charCodeAt(iOffset + 2),
// 							iByte4 = decoded_data.charCodeAt(iOffset + 3);

// 						var iLong = bBigEndian ? 
// 							(((((iByte1 << 8) + iByte2) << 8) + iByte3) << 8) + iByte4
// 							: (((((iByte4 << 8) + iByte3) << 8) + iByte2) << 8) + iByte1;
// 						if (iLong < 0) iLong += 4294967296;
// 						return iLong;
// 					};
			
// 			getSLongAt = function(iOffset, bBigEndian) {
// 				var iULong = getLongAt(iOffset, bBigEndian);
// 				if (iULong > 2147483647)
// 					return iULong - 4294967296;
// 				else
// 					return iULong;
// 			};
			
// 			var result = findEXIFinJPEG({ 
// 				getByteAt: function(idx) { return decoded_data.charCodeAt(idx); },
// 				getLength: function() { return decoded_data.length; },
// 				getShortAt: function(iOffset, bBigEndian) {
// 						var iShort = bBigEndian ? 
// 							(decoded_data.charCodeAt(iOffset) << 8) + decoded_data.charCodeAt(iOffset + 1)
// 							: (decoded_data.charCodeAt(iOffset + 1) << 8) + decoded_data.charCodeAt(iOffset)
// 						if (iShort < 0) iShort += 65536;
// 						return iShort;
// 					},
// 				getStringAt: function(a, b) { return decoded_data.substring(a, a+b); },
// 				getLongAt: getLongAt,
// 				getSLongAt: getSLongAt
// 			});
// 			return result;
// 		}
	
// 		var holder = document.getElementById('holder'),
// 			state = document.getElementById('status');

// 		if (typeof window.FileReader === 'undefined') {
// 		  state.className = 'fail';
// 		} else {
// 		  state.className = 'success';
// 		  state.innerHTML = 'A';
// 		}
		
// 		holder.ondragover = function () { this.className = 'hover'; return false; };
// 		holder.ondragend = function () { this.className = ''; return false; };
// 		holder.ondrop = function (e) {
// 		  this.className = '';
// 		  e.preventDefault();

// 		  var file = e.dataTransfer.files[0],
// 			  reader = new FileReader();
// 		  reader.onload = function (event) {
// 			exif_data = get_exif_data(event.target.result);
			
// 			for(var idx in exif_data)
// 			{
// 				var div = $("<tr />");
// 				div.append($("<td class=\"property\" />").text(idx));
// 				div.append($("<td class=\"value\" />").text(exif_data[idx]));
// 				$("#result").append(div);
// 			}
// 		  };
// 		  reader.readAsDataURL(file);

// 		  return false;
// 		};
		
// 		var keyStr = "ABCDEFGHIJKLMNOP" +
//                "QRSTUVWXYZabcdef" +
//                "ghijklmnopqrstuv" +
//                "wxyz0123456789+/" +
//                "=";
			   
// 		function decode64(input) {
// 			 var output = "";
// 			 var chr1, chr2, chr3 = "";
// 			 var enc1, enc2, enc3, enc4 = "";
// 			 var i = 0;

// 			 // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
// 			 var base64test = /[^A-Za-z0-9\+\/\=]/g;
// 			 if (base64test.exec(input)) {
// 				alert("There were invalid base64 characters in the input text.\n" +
// 					  "Valid base64 characters are A-Z, a-z, 0-9, '+', '/',and '='\n" +
// 					  "Expect errors in decoding.");
// 			 }
// 			 input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

// 			 do {
// 				enc1 = keyStr.indexOf(input.charAt(i++));
// 				enc2 = keyStr.indexOf(input.charAt(i++));
// 				enc3 = keyStr.indexOf(input.charAt(i++));
// 				enc4 = keyStr.indexOf(input.charAt(i++));

// 				chr1 = (enc1 << 2) | (enc2 >> 4);
// 				chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
// 				chr3 = ((enc3 & 3) << 6) | enc4;

// 				output = output + String.fromCharCode(chr1);

// 				if (enc3 != 64) {
// 				   output = output + String.fromCharCode(chr2);
// 				}
// 				if (enc4 != 64) {
// 				   output = output + String.fromCharCode(chr3);
// 				}

// 				chr1 = chr2 = chr3 = "";
// 				enc1 = enc2 = enc3 = enc4 = "";

// 			 } while (i < input.length);

// 			 return unescape(output);
// 		  }


export async function checkFileMetadata(fileRecord: Models.File, file: File) : Promise<SourceMetaData | undefined> {

  const userProfile = useUserProfileStore();

  //TODO: First see if there already is metaData for this file..
  const existingMetaData = await userProfile.getSourceMetadataRecordById(fileRecord.$id);
  if (existingMetaData) {
      console.log("FOUND EXISTING META DATA", existingMetaData);
      //NOTE: Don't need to continue...
      return existingMetaData;
  }

  console.log("NO EXISTING META DATA");

  let dimensions = { width: 0, height: 0 }
  let orientation = null;
  let captureDate = null;

  try {
    const exifData = await loadExif(URL.createObjectURL(file));

    if (exifData) {
      captureDate = exifData?.captureDate;
      dimensions = exifData.dimensions;
    }
  } catch (err) {
    console.log("ERROR: Unable to load EXIF meta data", err);
  }

  if (!captureDate) {
    //NOTE: Try the old way -- this will likely fail too, if EXIF failed...
    try {
      console.log("CHECKING CAPTURE DATE");
      captureDate = await getCaptureDatetime(URL.createObjectURL(file), fileRecord);
    } catch (err) {
      console.log("ERROR: Unable to get capture date", err);
    }
  }

  try {
    //TODO: Add the meta data to the sources table, since there doesn't appear to be a way to tie meta data directly to the storage object
    console.log("CHECKING ORIENTATION");
    orientation = await getSourceOrientation(URL.createObjectURL(file), fileRecord, dimensions);
  } catch (err) {
    console.log("ERROR: Unable to get orientation", err);
  }

  console.log("ADDING META DATA");

  const fileMetaData = {
      //fileId: fileRecord.$id, TODO: This ID is the same as the storage id.
      captureDatetime: captureDate as string,
      orientation: orientation as string,
      previewFileId: null,
      userId: userProfile.userId
  };

  console.log("FILE META DATA", fileMetaData);

  const newMetaData = await userProfile.saveMetadata(fileRecord.$id, fileMetaData);

  //if (newMetaData) 
      return newMetaData;

  //return undefined;

}

export function formatTime(timecode: any) {
  //const myDate = new Date(timecode * 1000); v0.0.15 way...
  const myDate = new Date(timecode); //1.0.0+ way
  return new Intl.DateTimeFormat('default', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }).format(myDate);
}

export function formatDatetime(timecode: any) {
  //const myDate = new Date(timecode * 1000); v0.0.15 way...
  const myDate = new Date(timecode); //1.0.0+ way
  console.log("Formatting datetime", myDate);
  return new Intl.DateTimeFormat('default', { dateStyle: 'medium', timeStyle: 'medium' }).format(myDate);
}


export async function convertFileToBlobAndDownload(file: any, name: string) {

  console.log("Converting to blob for download...", file, name);

  const blob = await fetch(file).then(r => r.blob())
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = name // add custom extension here
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
}


export async function copyToClipboard(text:string) {
  // let havePermission = false;
  // navigator.permissions.query({ name: "clipboard-write" as PermissionName }).then((result) => {
  //   if (result.state == "granted" || result.state == "prompt") {
  //     console.log("CLIPBOARD, Write access ranted!");
  //     havePermission = true;
  //   }
  // });
  try {
      console.log("WRITING TO CLIPBOARD", text);
      await navigator.clipboard.writeText(text);
      return true;
  } catch($e) {
    console.error($e);
    //if (!havePermission) {
        //TOOD: Anyway to automatically select the message text?
        alerta(text,'Copy Link', 'Copy this link to your clipboard, then paste it wherever you would like to share your video');        
    // } else {
    //   alerta(text,'Cannot copy to clipboard');
    // }    
    return false;
  }
}

export async function shareUrl() {
  if (await copyToClipboard(window.document.location.href))
      alerta("Paste it to wherever you want to share your video.", "Copied to Clipboard", "Share link copied to clipboard" );
}

export async function send() {
  var formattedBody = "Memory Tree Video \n " + window.document.location.href;
  var mailToLink = "mailto:?subject=" + encodeURIComponent("I made you a Memory Tree video!") + "&body=" + encodeURIComponent(formattedBody);
  window.location.href = mailToLink;
}


//NOTE: This function returns true indicating the user already exists, and action was taken. Otherwise it returns false;
export async function checkForExistingUser(email: string, err: Error) {
  
    //AppwriteException: A user with the same email already exists in the current project.
    // console.error(err);
    // console.log(err);
    if (err.name == "AppwriteException" && err.message.includes("already exists")) {
      //TODO: If the user already exists, offer to let them login
      const login = await confirm('Is this you? If you login, your current projects and any credits will be moved to your existing account. Would you like to login?','Account Exists',`An account already exists for ${email}.`);
      if (login) {
        //NOTE: Auto-fill the e-mail address
        await router.push({ name: 'login', params: { email } });
        return true;
      }
    }
    return false;
  
}


export async function toast (message:string, color = "") {
  const myToast = await toastController.create({
    message: message,
    duration: 3 * 1000, // 3 Seconds
    position: "bottom", //'top', 'middle' or 'bottom'
    color: color ?? undefined
  });

  return await myToast.present();
}

