import { Client, Functions, Account, Storage, Databases, Query, ID, Models, ImageFormat  } from 'appwrite';

//import { compress, decompress } from 'compress-json';
import { compress } from "compress-json/dist/index.js";

import router from "@/router";
import { useUserProfileStore } from './store/userProfileStore';
import { useTokenStore } from './store/tokenStore';
import { useJobStore } from './store/jobStore';

import { product } from '@/product';

//TODO: Move all these variables to .env or a shared module. There's not a lot of reason to have this dedicated SDK module

//const appEndPoint = window.document.location.protocol + import.meta.env.VITE_APP_ENDPOINT;
//const appEndPoint = import.meta.env.VITE_APP_ENDPOINT;
const appEndPoint = 'https://' + import.meta.env.VITE_APP_ENDPOINT;
const appUrl = window.document.location.protocol + "//" + window.document.location.hostname + ( window.document.location.port ? ":" + window.document.location.port : "");

//console.log("appUrl", appUrl);

//PROJECT
export const projectId = import.meta.env.VITE_PROJECT_ID;

//SOURCE FILES
const sourcesBucketId = import.meta.env.VITE_SOURCES_BUCKET_ID;
const outputsBucketId = import.meta.env.VITE_OUTPUT_BUCKET_ID;
const previewsBucketId = import.meta.env.VITE_PREVIEWS_BUCKET_ID;

export const sharedDatabaseId = import.meta.env.VITE_SHARED_DATABASE_ID;

//PRODUCT DATABASE
export const databaseId = product.databaseId;

//PRODUCT SPECIFIC COLLECTIONS
export const jobsCollectionId = product.jobsCollectionId; 
export const partsCollectionId = product.partsCollectionId; 
export const sharesCollectionId = product.sharesCollectionId; 

//SHARED COLLECTIONS
export const creditsCollectionId = import.meta.env.VITE_CREDITS_COLLECTION_ID; 
export const sourcesCollectionId = import.meta.env.VITE_SOURCES_COLLECTION_ID; 



var fatalSDKError: Error | string;

export const audioTypes = {
    "mp3": "audio/mpeg",
    "m4a": "audio/mpeg",
    "wav": "audio/x-wav",
}

export const videoTypes = {
    "mkv": "video/ogg", //TODO: Is this one right?
    "mov": "video/quicktime",
    "mp4": "video/mp4",
    "avi": "video/avi",
    "webm": "video/webm",
}

export const imageTypes = {
    "heic": "image/heic",
    "heif": "image/heif",
    "jfif": "image/jpeg",
    "jpeg": "image/jpeg",
    "jpg": "image/jpeg",
    "png": "image/png",
    "bmp": "image/bmp",
    //"webp": "image/webp", //TODO: TEST!!!
    "gif": "image/gif",
}

export const generateMetaPreviewTypes = {
    "heic": "image/heic",
    "heif": "image/heif",
    //"jfif": "image/jpeg", <<-- THIS NEEDS TO BE handled by extension
    ...videoTypes
}


//TODO: This should be auto-loaded from sequence ... why hard code it?
export enum PartType {
    intro = 'intro',
    title = 'title',
    transition = 'transition',
    picture_frame = 'picture_frame',
    loop = 'loop',
    primary_part = 'primary_part',
    cutaway = 'cutaway',
    outro = 'outro',
    credit = 'credit',
    sound = 'sound_fx',
    track = 'sound_track',
}

export enum JobStatus {
    new = "new", //Not ready for any action
    preview = "preview", //Server should generate preview
    generating = "generating", //Server is processing the preview
    encoding = "encoding", //The preview video is being encoded",
    ready = "ready", //Ready -- Preview ready for review
    problem = "problem", //The video preview had a problem",
    pending = "pending", //preview ready for approval
    processing = "processing", //Server should generate final
    finalizing = "finalizing", //The final video is being encoded",
    done = "done", //Job completed, ready to share
    canceled = "canceled", //Job Canceled, take no further action
    error = "error", //An error occurred, see error field for details
}

export const JobStatusDescription = {
    new: "New video, not ready to preview yet",
    preview: "A video preview will be generated",
    generating: "A video preview is being generated",
    encoding: "The preview video is being encoded",
    ready:  "Preview is ready for review",
    problem: "Problem generating preview",
    pending: "The final video will be generated",
    processing: "The final video is being generated",
    finalizing: "The final video is being encoded",
    done: "Video complete, ready to share",
    canceled: "Video was canceled, no further action will be taken",
    error: "An error has occurred"
}

export enum ShareStatus {
    processing = "processing",
    finalizing = "finalizing",
    updating = "updating",
    done = "done",
    error = "error",
    canceled = "canceled"
}


export interface Job extends Models.Document {
    title: string,
    audio: string,
    status: JobStatus,
    partOrder: string[],
    $id: string, 
    previewOutputId?: string | null,
    finalOutputId?: string,
    createdAt?: string,
    userId: string,
    deleted?: string,
    previewApproved?: string | null,
    debug?: string,
    error?: string,
}

export interface Part extends Models.Document {
    jobId: string,
    part: string, // intro, outro, title, picture_frame ... From sequence parts
    variant: string | "landscape" | "portrait" | "ordinal",
    file: string,
    title: string,
    subtitle?: string,
    quote?: string,
    date?: string, //Date
    textApproved?: string, //Date
    $id: string,    
    rendered?: string | null //Datetime
}


export interface SourceMetaData extends Models.Document {
    userId: string,
    fileId: string, //TODO: Do we really need this field? It's already the same as the source.$id...
    captureDatetime: string,
    orientation?: string | "portrait" | "landscape",
    previewFileId?: string | null,
    createdAt?: string,
    deleted?: string | null,
}

export interface Share extends Models.Document {
    userId: string,
    title: string,
    finalOutputId: string,
    status: string
}

export interface HistoryEvent extends Models.Document {
    userId: string,
    debit: number,
    credit: number,
    eventMeta: string
}


//SDK
export const client = new Client()
    .setEndpoint(appEndPoint) // Your API Endpoint
    .setProject(projectId) // Memory Tree project ID
;

export const storage = new Storage(client);
export const account = new Account(client);
export const functions = new Functions(client);
export const databases = new Databases(client); //, databaseId);




/* SOURCE FILES */

export async function createFile(file: File): Promise<Models.File> {

    console.log("SDK: CreateFile", file);

    // let storeFile = file;
    // if (typeof storeFile === 'Blob') {
    //     storeFile = new File([file], (file as Blob).name);
    // }

    //file.name = file.name.toLowerCase();    
    const newFile = await storage.createFile(sourcesBucketId, ID.unique(), file)
        .catch(errorHandler);

    // return promise.then(function (response:any) {
    //     console.log("SDK: SUCCESS:", response); // Success
    // }, function (error:any) {
    //     console.log("SDK: ERROR:", error); // Failure
    // });

    return newFile as Models.File;

}

export async function getMySourcesList(): Promise<Models.FileList> {

    console.log("SDK: GetMySourcesList");

    const list: Models.FileList | void = await storage.listFiles(sourcesBucketId, [Query.limit(100), Query.offset(0)])
        .catch(errorHandler);

    if (list && list.total > 0) {
            
            //TODO: The first request will only get us up to the first 100 ... need to keep going if there's more...
            let currentOffset = list.files.length; 
            while (list.total > list.files.length) {
                const moreList = await storage.listFiles(sourcesBucketId, [Query.limit(100), Query.offset(currentOffset)])
                    .catch(errorHandler);

                if (moreList) {
                    //console.log("Adding Sources:", moreList.files);
                    list.files.push(...moreList.files);
                }
                currentOffset = list.files.length; 
        }
    }

    //console.log("SDK: FINISHED GetMySourcesList", list);

    return list as Models.FileList;
}


/* SOURCE META DATA */

export async function getMySourcesMetadataList(userId: string): Promise<Models.DocumentList<Models.Document>> {

    console.log("SDK: getMySourcesMetadataList");

    const list: Models.DocumentList<Models.Document> | void = await databases.listDocuments(sharedDatabaseId, sourcesCollectionId, [Query.equal('userId', userId), Query.limit(100), Query.offset(0)])
        .catch(errorHandler);

    if (list && list.total > 0) {
            
            //TODO: The first request will only get us up to the first 100 ... need to keep going if there's more...
            let currentOffset = list.documents.length; 
            while (list.total > list.documents.length) {
                const moreList = await databases.listDocuments(sharedDatabaseId, sourcesCollectionId, [Query.equal('userId', userId), Query.limit(100), Query.offset(currentOffset)])
                    .catch(errorHandler);

                if (moreList) {
                    //console.log("Adding Sources:", moreList.files);
                    list.documents.push(...moreList.documents);
                }
                currentOffset = list.documents.length; 
        }
    }

    console.log("SDK: FINISHED getMySourcesMetadataList", list);

    return list as Models.DocumentList<Models.Document>;
}

export async function getSourceMetaDataRecord(sourceFileId: string): Promise<SourceMetaData | undefined> {

    console.log("SDK: getSourceMetaData");

    let sourceMetaData = null;
    try {
        //NOTE: The metadata may not exist, so we don't care about any errors here...
        sourceMetaData = await databases.getDocument(sharedDatabaseId, sourcesCollectionId, sourceFileId);
    } catch (error) {
        //NOTE: We don't care about any error here
    }

    console.log("SDK: SourceMetaData", sourceMetaData);
    if (sourceMetaData) {
        return sourceMetaData as SourceMetaData;
    }

    return undefined;
}

export async function saveMetaData(fileId: string, metaData: any) : Promise<SourceMetaData | undefined> {

    console.log("SDK: saveMetaData", fileId, metaData);    
    
    const addMetaData = await databases.createDocument(sharedDatabaseId, sourcesCollectionId, fileId, metaData)
        .catch(errorHandler);

    return addMetaData as SourceMetaData;
}

export async function deleteMetadataById( fileId: any) : Promise<any> {
    console.log("SDK: deleteMetadataById", fileId);

    const removeMetadata = await databases.deleteDocument(sharedDatabaseId, sourcesCollectionId, fileId)
        .catch(errorHandler);
        // .then(function (response) {
        //     console.log("SDK: SUCCESS:", response); // Success
        // }, function (error) {
        //     console.log("SDK: ERROR:", error); // Failure
        // });    

    return removeMetadata;

}

export async function updateMetaData( metaData: any) : Promise<SourceMetaData | undefined> {

    console.log("SDK: updateMetaData", metaData);    

    //TODO: I don't know why these now have to be removed from the object before updating?
    delete (metaData as any).$collectionId;
    delete (metaData as any).$databaseId;    
    
    const updateMetaData = await databases.updateDocument(sharedDatabaseId, sourcesCollectionId, metaData.$id, metaData)
        .catch(errorHandler);

    return updateMetaData as SourceMetaData;
}



/* JOBS */

export async function startNewJob(job: Job): Promise<Job> {

    console.log("SDK: StartNewJob", job);

    const newJob = await databases.createDocument(databaseId, jobsCollectionId, ID.unique(), job)
        .catch(errorHandler);
        // .then(function (response) {
        //     console.log("SDK: SUCCESS:", response); // Success
        // }, function (error) {
        //     console.log("SDK: ERROR:", error); // Failure
        // });    

    return newJob as Job;

}

export async function getJobById(jobId: string): Promise<Job | undefined> {

    console.log("SDK: getJobById");

    const job = await databases.getDocument(databaseId, jobsCollectionId, jobId);
        //.catch(errorHandler);
        // .then(function (response) {
        //     console.log("SDK: SUCCESS:", response); // Success
        // }, function (error) {
        //     console.log("SDK: ERROR:", error); // Failure
        // });    
    console.log("SDK: JOB", job);
    return job as Job;
}

export async function getShareById(jobId: string): Promise<Share | undefined> {

    console.log("SDK: getShareById");

    const job = await databases.getDocument(databaseId, sharesCollectionId, jobId);
        //.catch(errorHandler);
        // .then(function (response) {
        //     console.log("SDK: SUCCESS:", response); // Success
        // }, function (error) {
        //     console.log("SDK: ERROR:", error); // Failure
        // });    
    console.log("SDK: SHARE", job);
    return job as Share;
    
}

export async function updateJob(job: Job) {

    console.log("SDK: UpdateJob", job);

    //TODO: I don't know why these now have to be removed from the object before updating?
    delete (job as any).$collectionId;
    delete (job as any).$databaseId;

    const updatedJob = await databases.updateDocument(databaseId, jobsCollectionId, job.$id, job)
        .catch(errorHandler);
        // .then(function (response) {
        //     console.log("SDK: SUCCESS:", response); // Success
        // }, function (error) {
        //     console.log("SDK: ERROR:", error); // Failure
        // });    

    return updatedJob;

}


/* JOB PARTS */

export async function getMyJobPartsList(jobId: string): Promise<Models.DocumentList<Part>> {

    console.log("SDK: GetMyJobPartsList");

    const list = await databases.listDocuments(databaseId, partsCollectionId, [
        Query.equal('jobId', jobId),
        Query.limit(100),
        Query.offset(0)
    ])
        .catch(errorHandler);

        
    if (list && list.total > 0) {
            
            //TODO: The first request will only get us up to the first 100 ... need to keep going if there's more...
            let currentOffset = list.documents.length; 
            while (list.total > list.documents.length) {
                const moreList = await databases.listDocuments(databaseId, partsCollectionId, [
                    Query.equal('jobId', jobId),
                    Query.limit(100),
                    Query.offset(0)
                ])
                    .catch(errorHandler);

                if (moreList) {
                    console.log("Adding Sources:", moreList.documents);
                    list.documents.push(...moreList.documents);
                }
                currentOffset = list.documents.length; 
        }
    }

    console.log("SDK: FINISHED PARTS", list);
    return list as Models.DocumentList<Part>;
}


export async function addPart(part: Part) {

    console.log("SDK: AddPart", part);

    const newPart = await databases.createDocument(databaseId, partsCollectionId, ID.unique(), part)
        .catch(errorHandler);
        // .then(function (response) {
        //     console.log("SDK: SUCCESS:", response); // Success
        // }, function (error) {
        //     console.log("SDK: ERROR:", error); // Failure
        // });    

    return newPart;

}

export async function removePart(part: Part) {

    console.log("SDK: RemovePart", part);

    const removePart = await databases.deleteDocument(databaseId, partsCollectionId, part.$id)
        .catch(errorHandler);
        // .then(function (response) {
        //     console.log("SDK: SUCCESS:", response); // Success
        // }, function (error) {
        //     console.log("SDK: ERROR:", error); // Failure
        // });    

    return removePart;

}


export async function updatePart(part: Part) {

    console.log("SDK: UpdatePart", part);

    //TODO: I don't know why these now have to be removed from the object before updating?
    delete (part as any).$collectionId;
    delete (part as any).$databaseId;

    const updatedPart = await databases.updateDocument(databaseId, partsCollectionId, part.$id, part)
        .catch(errorHandler);
        // .then(function (response) {
        //     console.log("SDK: SUCCESS:", response); // Success
        // }, function (error) {
        //     console.log("SDK: ERROR:", error); // Failure
        // });    

    return updatedPart;

}

export async function removeJob(job: Job) {

    console.log("SDK: RemoveJob");

    //TODO: Go through the parts attached, and remove them...
    //TODO: Do the files also need to be removed?

    const newPart = await databases.deleteDocument(databaseId, jobsCollectionId, job.$id);
        // .then(function (response) {
        //     console.log("SDK: SUCCESS:", response); // Success
        // }, function (error) {
        //     console.log("SDK: ERROR:", error); // Failure
        // });    

    return newPart;

}


export async function removeOutput(outputFileId: string) {

    console.log("SDK: RemoveOutput", outputFileId);

    const newPart = await storage.deleteFile(outputsBucketId,outputFileId)
        .catch(errorHandler);
        // .then(function (response) {
        //     console.log("SDK: SUCCESS:", response); // Success
        // }, function (error) {
        //     console.log("SDK: ERROR:", error); // Failure
        // });    

    return newPart;

}

export async function updateProfileEmail(email: string, password: string) {

    console.log("SDK: updateProfileEmail", email);

    const updatedProfile = await account.updateEmail(email, password);
        //.catch(errorHandler);
        // .then(function (response) {
        //     console.log("SDK: SUCCESS:", response); // Success
        // }, function (error) {
        //     console.log("SDK: ERROR:", error); // Failure
        // });    

    return updatedProfile;

}



export async function recordPaymentEvent(details: any) {

    console.log("SDK: recordPaymentEvent", details);

    const compressedVal = compress(details);

    // console.log("Uncompressed", JSON.stringify(details).length);
    // console.log("Uncompressed String", JSON.stringify(details));

    // console.log("Compressed", JSON.stringify(compressedVal).length);
    // console.log("Compressed String", JSON.stringify(compressedVal));

    

    //NOTE: if (compressedVal.length > 8191) throw new Error("Request body is too long to send");
    //NOTE: Let the sdk worry about rejecting it, since it's a restriction with it...

    const executionResult = await functions.createExecution('processPaymentPP-v1', JSON.stringify(compressedVal))
        .catch(errorHandler);

        
    //TODO: Should this also be where we update the user profile with any additional info?
    // const updatedProfile = await account.updateEmail(email, password)
    //     .catch(errorHandler);
        // .then(function (response) {
        //     console.log("SDK: SUCCESS:", response); // Success
        // }, function (error) {
        //     console.log("SDK: ERROR:", error); // Failure
        // });    

    return executionResult;
}


export async function startFinalVideo(jobId: string, credits: Number) {

    console.log("SDK: startFinalVideo", jobId, credits);    

    const executionResult = await functions.createExecution('renderFinalVideo-v1', JSON.stringify({ request:'start-job-final' , jobId, credits }));
        //.catch(errorHandler);

    console.log("RESUT:", executionResult);

    return executionResult;
}



export async function updateAccountPassword(newPassword: string, oldPassword: string) {

    console.log("SDK: updateAccountPassword", newPassword, oldPassword);    
    
    const accountUpdate = await account.updatePassword(newPassword, oldPassword)
        .catch(errorHandler);

    return accountUpdate;
}



export async function accountEmailVerified(userId: string) {

    const creditEventData = {
        request: 'email-verified',
        param: '',
    }
    
    //NOTE: With the new session, issue a starting credit? Not going to wait for this, it takes too long... And we won't report any errors.
    const creditEventV1 = functions.createExecution('creditEvent-v1',JSON.stringify(creditEventData));       

}


export async function logout() {

    console.log("SDK: logout");

    const result = await account.deleteSessions()
        .catch(errorHandler);
        // .then(function (response) {
        //     console.log("SDK: SUCCESS:", response); // Success
        // }, function (error) {
        //     console.log("SDK: ERROR:", error); // Failure
        // });    

    return result;

}


export function getSourcePreview(fileId?: string): string {

    if (!fileId) return ""; //NOTE: Nothing to do here

    const userProfile = useUserProfileStore();
    
    //TODO: This gets called alot!! and it's very slow...
    //console.log("SDK: GetStoragePreview");
    //TODO: First, see if we can find the source...
    const sourceFile = userProfile.getSourceFileRecordById(fileId);
    // if (sourceFile) {
    //     if ([
    //         imageTypes.heic, 
    //         imageTypes.heif, 
    //         imageTypes.jfif, 
    //         ...Object.values(videoTypes) //NOTE: ANY type of video
    //     ].includes(sourceFile?.mimeType as string)) {
    //         //NOTE: This type of image uses the alternate previews bucket instead...
    //         return storage.getFilePreview(previewsBucketId, fileId, 0, 164).toString();//NOTE: DON'T Specify one attribute of size to retain aspect ratio
    //     }
    // }
    
    //NOTE: We can get a preview of this file directly...
    return getStoragePreview(fileId);

}

export async function blockAccount() {

    //NOTE: We can get a preview of this file directly...
    return account.updateStatus();

}

export async function getPreferences() {
    return account.getPrefs();
}

export async function updatePreferences(newPrefs: Object) {
    return account.updatePrefs(newPrefs);
}

export function getStoragePreview(fileId?: string): string {

    if (!fileId) return ""; //NOTE: Nothing to do here

    //NOTE: We can get a preview of this file directly...
    return storage.getFilePreview(sourcesBucketId, fileId, 
        0, //Width
        164, //Height
        undefined, //Gravity
        undefined, // quality
        undefined, // borderWidth
        undefined, // borderColor
        undefined, // borderRadius
        undefined, // Opacity
        undefined, // Rotation
        undefined, // Background
        ImageFormat.Webp // Output
    ).toString(); //NOTE: DON'T Specify one attribute of size to retain aspect ratio


}

export function getSourceMetaPreviewImage(fileId?: string): string {

    if (!fileId) return ""; //NOTE: Nothing to do here

    //NOTE: We can get a preview of this file directly...
    return storage.getFilePreview(previewsBucketId, fileId, 
            0, //Width
            164, //Height
            undefined, //Gravity
            undefined, // quality
            undefined, // borderWidth
            undefined, // borderColor
            undefined, // borderRadius
            undefined, // Opacity
            undefined, // Rotation
            undefined, // Background
            ImageFormat.Webp // Output
        ).toString(); //NOTE: DON'T Specify one attribute of size to retain aspect ratio

}
export function viewMetaPreviewFile(fileId?: string): string {

    if (!fileId) return ""; //NOTE: Nothing to do here

    console.log("SDK: viewMetaPreviewFile",fileId);

    return storage.getFileView(previewsBucketId, fileId).toString();

}


export function getOutputStoragePreview(fileId: string): string {

    if (!fileId) return ""; //NOTE: Nothing to do here

    console.log("SDK: getOutputStoragePreview",fileId);

    return storage.getFileView(outputsBucketId, fileId).toString(); 

}

export function viewOutputFile(fileId?: string): string {

    if (!fileId) return ""; //NOTE: Nothing to do here

    console.log("SDK: getOutputFile",fileId);

    return storage.getFileView(outputsBucketId, fileId).toString();

}

export function downloadOutputFile(fileId?: string): string {

    if (!fileId) return ""; //NOTE: Nothing to do here

    console.log("SDK: downloadOutputFile",fileId);

    return storage.getFileDownload(outputsBucketId, fileId).toString();

}



/* SOURCE FILES */
export function viewSourceFile(fileId?: string): string {

    if (!fileId) return ""; //NOTE: Nothing to do here

    //console.log("SDK: getSourceFile",fileId);

    return storage.getFileView(sourcesBucketId, fileId).toString();

}


/* SHARES */ 

export async function getSharesList(shareIds: string[]): Promise<Models.DocumentList<Models.Document>> {

    const uniqueShareIds = [...new Set(shareIds.map((shareId) => shareId))];

    console.log("SDK: getSharesList", uniqueShareIds);

    const list: Models.DocumentList<Models.Document> | void = await databases.listDocuments(databaseId, sharesCollectionId, [Query.equal('$id', shareIds), Query.limit(100), Query.offset(0)])
        .catch(errorHandler);

    if (list && list.total > 0) {
            
            //TODO: The first request will only get us up to the first 100 ... need to keep going if there's more...
            let currentOffset = list.documents.length; 
            while (list.total > list.documents.length) {
                const moreList = await databases.listDocuments(databaseId, sharesCollectionId, [Query.equal('$id', shareIds), Query.limit(100), Query.offset(currentOffset)])
                    .catch(errorHandler);

                if (moreList) {
                    //console.log("Adding Sources:", moreList.files);
                    list.documents.push(...moreList.documents);
                }
                currentOffset = list.documents.length; 
        }
    }

    console.log("SDK: FINISHED getSharesList", list);

    return list as Models.DocumentList<Models.Document>;
}



/* ACCOUNT */

export async function startTemporaryAccount() {

    console.log("SDK: StartTemporaryAccount");

    const session = await account.createAnonymousSession()
        .catch(errorHandler);

    //TODO: Moved to Account Credit Event Function fired by user create trigger
    //TODO: Maybe not ... that event is not triggered for temporary user ... ?
    const creditEventData = {
        request: 'new-account',
        param: ''
    }
    
    //NOTE: With the new session, issue a starting credit? Not going to wait for this, it takes too long... And we won't report any errors.
    const creditEventV1 = functions.createExecution('creditEvent-v1',JSON.stringify(creditEventData));       

    return session;

}


export async function sendWelcomeEmail(userId: string) {


    const notificationRequest = {
        request: "welcome",
        param: userId
    }

    console.log("SDK: sendWelcomeEmail", 'notificationsV1', JSON.stringify(notificationRequest));

    //NOTE: With the new session, issue a starting credit?
    const sendWelcomeEmailResponse = await functions.createExecution('notifications-v1',JSON.stringify(notificationRequest))
        .catch(errorHandler);

    return sendWelcomeEmailResponse;

}

//NOTE: Merge an unregistered account into an existing account after login
export async function mergeUnregisteredAccount(existingSession: Models.Session) {
 
    console.log("SDK: mergeUnregisteredAccount");

    const maintenanceRequest = {
        request: "merge-unregistered-account",
        userId: existingSession.userId ? existingSession.userId : existingSession.$id
    }

    //NOTE: With the new session, issue a starting credit?
    const mergeUnregisteredAccount = await functions.createExecution('accountMaintenance-v1',JSON.stringify(maintenanceRequest))
        .catch(errorHandler);

    return mergeUnregisteredAccount;

}



export async function updateProfileName(name: string) {

    console.log("SDK: updateProfileName", name);

    const updatedProfile = await account.updateName(name);
        //.catch(errorHandler);
        // .then(function (response) {
        //     console.log("SDK: SUCCESS:", response); // Success
        // }, function (error) {
        //     console.log("SDK: ERROR:", error); // Failure
        // });    

    return updatedProfile;

}

export function subscribeToRealTimeEvents(userId: string) {

    if (!userId) {
        //NOTE: Not going to subscribe to anything if we don't have a user to watch for...
        return;
    }

    
    
    const events = [
            `databases.${sharedDatabaseId}.collections.${sourcesCollectionId}.documents`,
            `databases.${sharedDatabaseId}.collections.${creditsCollectionId}.documents`, 
            `databases.${databaseId}.collections.${jobsCollectionId}.documents`, 
            `buckets.${sourcesBucketId}.files`
        ];

    console.log("*** SUBSCRIBE TO REALTIME EVENTS ***", events, userId);
    
    return client.subscribe(events, response => {

        const userProfile = useUserProfileStore();

        const events = response.events;
        //console.log("REALTIME EVENT: ", response);
        //console.log("REALTIME EVENTS: ", events);
        
        let eventHandled = false;
        if(events.includes(`databases.${sharedDatabaseId}.collections.${creditsCollectionId}.documents.*.create`)) {
            // Log when a new file is uploaded
            console.log("HISTORY EVENT:", response.payload);
            userProfile.addLocalHistoryEvent(response.payload as HistoryEvent);
            eventHandled = true;
        }
        if(events.includes(`buckets.${sourcesBucketId}.files.*`)) {
            // Log when a new file is uploaded
            console.log("SOURCE EVENT:", response.payload);
            eventHandled = true;
        }
        if(events.includes(`databases.${databaseId}.collections.${jobsCollectionId}.documents.*.update`)) {
            // Log when a new file is uploaded
            const jobs = useJobStore();
            console.log("JOB UPDATE EVENT:", response.payload);            
            jobs.jobUpdated(response.payload as Job);
            eventHandled = true;
        }
        if(events.includes(`databases.${sharedDatabaseId}.collections.${sourcesCollectionId}.documents.*.update`)) {
            // Log when a new file is uploaded
            console.log("METADATA EVENT:", response.payload);
            userProfile.metadataUpdated(response.payload as SourceMetaData);
            eventHandled = true;
        }

        if(!eventHandled) {
            console.log("WARNING: Unhandled Realtime Event - ", response);
        }

    });
}



export async function updateProfilePhone(phone: string, password: string) {

    console.log("SDK: updateProfilePhone", phone);

    const updatedProfile = await account.updatePhone(phone, password);
        //.catch(errorHandler);
        // .then(function (response) {
        //     console.log("SDK: SUCCESS:", response); // Success
        // }, function (error) {
        //     console.log("SDK: ERROR:", error); // Failure
        // });    

    return updatedProfile;

}




export async function registerAccount(email: string, password: string) {

    console.log("SDK: registerAccount", email);

    const newAccount = await account.create(ID.unique(), email, password)
        .catch(errorHandler);
        // .then(function (response) {
        //     console.log("SDK: SUCCESS:", response); // Success
        // }, function (error) {
        //     console.log("SDK: ERROR:", error); // Failure
        // });    

    return newAccount;

}


export async function createRecovery(email: string) {

    console.log("SDK: createRecovery", email);

    const accountRecovery = await account.createRecovery(email, appUrl + "/recovery" )
        .catch(errorHandler);
    
    return accountRecovery;

}

export async function createVerification(url:string) {
    
    return account.createVerification(url);

}

export async function sendEmailVerification(userId: string, email: string) {

    //TODO: Moved to Account Credit Event Function fired by user create trigger
    //TODO: Maybe not ... that event is not triggered for temporary user ... ?
    const creditEventData = {
        request: 'new-account',
        param: ''
    }
    
    //NOTE: With the new session, issue a starting credit? Not going to wait for this, it takes too long... And we won't report any errors.
    const creditEventV1 = functions.createExecution('creditEvent-v1',JSON.stringify(creditEventData));       

    //TODO: Fire notification function with verification e-mail...
    //NOTE: Return URL will be https://memory-tree.studiob4.com/verify/${userId}/$email
    //TODO: Probably need to encode the e-mail address..
    //TODO: This will only work if the user is currently logged in...

}

/* CREDITS */


export async function getMyHistory(userId: string): Promise<Models.DocumentList<Models.Document>> {

    console.log("SDK: getMyHistory");

    const list = await databases.listDocuments(sharedDatabaseId, creditsCollectionId, [
        Query.equal('userId', userId),        
        Query.limit(100),
        Query.offset(0)
    ])
        .catch(errorHandler);


       
        if (list && list.total > 0) {
            
            //TODO: The first request will only get us up to the first 100 ... need to keep going if there's more...
            let currentOffset = list.documents.length; 
            while (list.total > list.documents.length) {
                const moreList = await databases.listDocuments(sharedDatabaseId, creditsCollectionId, [
                    Query.equal('userId', userId),        
                    Query.limit(100),
                    Query.offset(0)
                ])
                                .catch(errorHandler);

                if (moreList) {
                    console.log("Adding Sources:", moreList.documents);
                    list.documents.push(...moreList.documents);
                }
                currentOffset = list.documents.length; 
        }
    }

    //console.log("SDK: FINISHED History", list);
    return list as Models.DocumentList<Models.Document>;
}



/* ERROR HANDLING */

export function errorHandler (err: Error | string, networkOnly = false) {
    console.log("SDK ERROR:", err);
    //alert("FAILURE: " + err.message );
    fatalSDKError = err;
    if (err == "AppwriteException: Network request failed") { 
        router.push({ name:"no-network" });
        return; 
    }
    if (networkOnly) return; //NOTE: If we're only looking for a network error, we won't redirect...
    router.push({ name:"error" });
}

export function getLastFatalError() {
    console.log("SDK ERROR:", fatalSDKError);
    if (!fatalSDKError) fatalSDKError = new Error("UNKNOWN");        
    if (fatalSDKError instanceof Error) {
        console.log(JSON.stringify(fatalSDKError));
        if (fatalSDKError.name == "AppwriteException") {
            switch ((fatalSDKError as any).type) {
                case "user_blocked": return "Account has been deleted.";
                case "user_already_exists": return "Email is already registered. Please login.";
            }
            // return errMessage.replace(["AppwriteException:", "ERROR:"], "") //NOTE: The error has a message...
        }
        return (fatalSDKError as any).message;
    } else {    
       return fatalSDKError; //The error is probably just a string, pass as is...
    }
}