import {defineStore, skipHydrate} from 'pinia';

import { 
    Job, 
    Part, 
    PartType,
    //createTemporaryAccount, 
    createFile, 
    getMyJobPartsList,
    addPart,
    updateJob,
    removePart,
    removeOutput,
    JobStatus,
    startNewJob,
    updatePart,
    startFinalVideo,
    getJobById,
    errorHandler
} from "@/sdk";

import { alertController } from '@ionic/core'; 

import { Models, ID } from 'appwrite';

import { useUserProfileStore } from '@/store/userProfileStore';
import { useJobStore } from '@/store/jobStore';

import { alerta } from '@/utility';

import router from '@/router';
import { start } from 'repl';

import pinia from "@/store";

import { product, sequence } from "@/product";


function _isStatusAbove(baseStatus: JobStatus, checkStatus: JobStatus) {

    const currentOrdinalStatus = Object.values(JobStatus).indexOf(baseStatus);
    const checkOrdinalStatus = Object.values(JobStatus).indexOf(checkStatus);

    return checkOrdinalStatus <= currentOrdinalStatus;
                
}
function _isStatusBelow(baseStatus: JobStatus, checkStatus: JobStatus) {

    const currentOrdinalStatus = Object.values(JobStatus).indexOf(baseStatus);
    const checkOrdinalStatus = Object.values(JobStatus).indexOf(checkStatus);

    return checkOrdinalStatus >= currentOrdinalStatus;
                
}

function _filterCustomParts(parts: Models.DocumentList<Part>) {
    if (parts && parts.total > 0) 
        return parts.documents.filter(part => part.part === product.primary_part);
    return [] as Part[];
}

export const useActiveJobStore = defineStore('activeJob', {
    state: () => {
        return { 
            loading: false,
            job: undefined as Job | undefined,
            parts: {} as Models.DocumentList<Part>,            
        }
    },
    actions: {
        async startJob (): Promise<Job | undefined> {

            const defaultFirstProjectName = "My First " + product.name;

            const jobs = useJobStore(pinia);
            const userProfile = useUserProfileStore(pinia);
            console.log("Starting new job...", jobs.all);
            if ((userProfile.isLoggedIn || userProfile.isTemporaryUser) && jobs.all.total == 0) {
                //NOTE: Special handling for the very first job -- for that one, we'll use a generic project name to eliminate a little friction...
                const newJob = await this.addJob(defaultFirstProjectName).catch(errorHandler);
                if (!newJob) return; //TODO: Error??
                router.push({name: "video.personalize", params: {id: newJob.$id} }); ///`/video/${newJob.$id}/personalize`);
                return newJob;
            }

            if (!userProfile.isLoggedIn) {
                //NOTE: Special handling -- new users don't get setup until the very last minute, when they're sending sources. That's handled by Step1.vue
                router.push({name: 'video.start' }); // /video/personalize/personalize`/video/personalize`);
                return;
            }

            let newJob = undefined;

            const alert = await alertController.create({
              header: 'New Video Project',
              subHeader: 'Enter a working title for your new video',
              //message: 'This will be used to refer to your video, and publicly displayed when your video is eventually shared. You can change it.',
              backdropDismiss: false,
              buttons: [{
                    text: 'OK',
                    handler: async (data) => {
                                    newJob = await this.addJob(data[0]).catch(errorHandler);
                                    if (!newJob) return; //TODO: Error??
                                    router.push({name: "video.personalize", params: {id: newJob.$id} });
                                    alertController.dismiss();
                                }
                        },'Cancel'],
              keyboardClose: true,
              inputs: [
                {
                  placeholder: 'Project Name',
                  value: '',
                  attributes: {
                    maxlength: 50,
                  },
                },
              ],
            });
    
            const result = await alert.present();

            return newJob;
    
          },    
          async addJob (title: string) {
    
            //jobs.loading = true;
    
            await this.newJob(title);
    
            //NOTE: Make sure the master list gets updated...
            //TODO: Doesn't seem necessary?
            //jobs.loadJobs();
    
            //NOTE: DO NOT redirect here -- Store is not responsible for UI
             if (this.job) {
            //     router.push(`/video/${this.job.$id}/personalize`);
                 return this.job;
            // } else {
            //     alert("There was a problem starting a new video.");
             }
            //jobs.loading = false;
    
            },    
        async newJob(name = 'Memory Tree') {
            console.log("newJob");

            this.loading = true;
            
            //TODO: Start a new job...

            const userProfile = useUserProfileStore(pinia);

            console.log("CURRENT USER", userProfile.session);
            console.log(userProfile.session);

            if (!userProfile.userId) throw new Error("No current user.");

            this.job = await startNewJob({
                title: name,                
                //audio: '',
                status: JobStatus.new,
                userId: userProfile.userId //TODO: Don't understand why sometimes there's a userId, sometimes there's not...
            } as Job);            

            const jobs = useJobStore(pinia);
            jobs.loadJobs();


            for (const key in product.new_project_defaults) {
                if (product.new_project_defaults.hasOwnProperty(key)) {

                    console.log("*****", key, product.new_project_defaults);
                    const updatedDefaults = product.new_project_defaults[key];

                    //NOTE: Variable replacement of the default string...
                    if (updatedDefaults.title) {
                        updatedDefaults.title = updatedDefaults.title.replace("%PROJECTNAME%",name);
                    }
                    
                    await this.addPart(key, updatedDefaults);
                }
            }

            // await this.addPart(PartType.intro, {title: name, subtitle:"Memory Tree by Studio B⁴"});
            // await this.addPart(PartType.title, {title:"A look back at some", subtitle:"of our memories"});

            // //TODO: Need a marker or something here for auto-sequencing...

            // await this.addPart(PartType.outro, {title: name, subtitle:"Memory Tree by Studio B⁴"});
            // await this.addPart(PartType.credit, {variant: 1});

            this.loading = false;

            return this.job;
            
        },
        async loadJob(newJob: Job) {
            console.log("LOAD JOB", newJob);

            this.loading = true;

            this.job = newJob;
            await this.loadParts();
            this.loading = false;
        },
        async refreshJob() {
            if (!this.job) throw new Error("Job not loaded");

            console.log("RefreshJob",this.job.$id);

            //this.loading = true;
            this.job = await getJobById(this.job.$id);
            
            //this.loading = false;
        },
        async loadParts() {

            if (!this.job) {
                throw new Error("Could not load job parts.");
                return;
            }
            this.parts = await getMyJobPartsList(this.job.$id);
            console.log("LOAD PARTS", this.parts);

        },
        addSourceFile(file: File) {
            //TODO: Add to server
            //TODO: Add part
        },
        async addPart(part: string, settings = {}, file = null as Models.File | null ) {

            if (!this.job) {
                throw new Error("Could not add part to unknown job.");
                return;
            }

            //console.log("this.job", this.job);
            //console.log("this.parts.documents", this.parts.documents);
            

            //NOTE: See if the parts list has a outro or credits
            const outro = this.outroPart;
            const credits = this.creditsPart;
            //TODO: Check to see that order of the outro and credits has not been changed
            //TODO: Add the new parts, and order accordingly

            const partDef = sequence.find((partDef: any) => partDef.part == part);

            //console.log("AddPart",partDef);

            const myJobId = this.job.$id;
            //TODO: Add to server
            //TODO: Add part
            const myPart: Part = {
                part: part,
                variant: partDef?.variant?.toString() ?? 'ordinal', 
                jobId: myJobId,
                file: file?.$id
            } as Part

            Object.assign(myPart, settings);

            //NOTE: Need to make sure variant is always passed as string from here...
            if (myPart.variant) myPart.variant = myPart.variant.toString();

            console.log("ADDING PART", myPart);

            const myNewPart: Part = await addPart(myPart) as Part;
            const currentPartOrder = this.job.partOrder;

            //console.log("Starting Part Order", currentPartOrder);

            if (outro) {
                currentPartOrder.splice(currentPartOrder.indexOf(outro.$id),0, myNewPart.$id);
            } else if (credits) {
                currentPartOrder.splice(currentPartOrder.indexOf(credits.$id),0, myNewPart.$id);
            } else {
                currentPartOrder.push(myNewPart.$id);
            }

            //console.log("New Part Order", currentPartOrder);

            await this.updatePartOrder(currentPartOrder);

            //TOOD: This is safe, but is it best to reload the whole part list? Do we NEED to reload the whole part list??
            this.loadParts(); 
        },
        async updatePartOrder(newOrder: string[]) {

            //console.log("UpdatePartOrder",newOrder);

            if (!this.job) {
                throw new Error("Could not add part to unknown job.");                
            }

            //NOTE: We've made a change to the job, so no matter what the case was, it's back to a new status, which will force a refresh.
            //NOTE: This happens anytime a part is added, or reordered
            this.job.status = JobStatus.new; //NOTE: This will be saved by the updatePartOrder that happens next...

            this.job.partOrder = newOrder;
            
            await updateJob(this.job);

            //console.log("UPDATED JOB PART ORDER:", this.job.partOrder);

        },
        async removePart(part: Part) {

            if (!this.job) {
                throw new Error("Could not remove part to unknown job.");                
            }

            //TODO: Should we test to make sure the job can still be changed?

            console.log("Remove Part");            

            await removePart(part);

            //NOTE: Remove the part from the jobs partOrder list
            const currentPartOrder = this.job?.partOrder;
            if (currentPartOrder) {
                const existingIndex = currentPartOrder?.indexOf(part.$id);
                currentPartOrder.splice(existingIndex, 1);
            }
            
            //NOTE: We've made a change to the job, so no matter what the case was, it's back to a new status, which will force a refresh.                
            this.job.status = JobStatus.new; 
            this.update();

            this.parts.documents.splice(this.parts.documents.indexOf(part), 1);

        },
        async update() {

            if (!this.job) {
                throw new Error("Could not load job parts.");
                return;
            }
            await updateJob(this.job);
        },
        async updatePart(part:Part) {

            if (!this.job) {
                throw new Error("Job is not set.");
            }

            //NOTE: We've made a change to the job, so no matter what the case was, it's back to a new status, which will force a rebuild of preview.                
            this.job.status = JobStatus.new; 
            this.update();

            return await updatePart(part);
        },
        // async waitAndAdd() {
        //     setTimeout(() => this.count++, 2000);
        // }
        isStatusAbove(checkStatus: JobStatus) {

            if (!this.job) return;

            return _isStatusAbove(this.job.status, checkStatus);
                        
        },
        isStatusBelow(checkStatus: JobStatus) {

            if (!this.job) return;

            return _isStatusBelow(this.job.status, checkStatus);
                        
        },
        getManifestDef(partName: string) {
            return sequence.find((sequenceDef: any) => sequenceDef.part == partName);
        },
        async startFinal(){

            if (!this.job) throw new Error("No current job");

            const userProfile = useUserProfileStore(pinia);

            const result = await startFinalVideo(this.job.$id, this.creditsCost);

            const updatedJob = await this.refreshJob();

            console.log("START FINAL RESULT:", result, updatedJob);

            if (result) {
                //TODO: If statusCode == 500, there was a problem

            }

            //TODO: If updated JobStatus is processing, it was charged and started processing anyway...                
            if (this.job.status == JobStatus.pending) {
                userProfile.loadHistory();
            }

            return result;

        },
        async startPreview(): Promise<boolean> {

            if (!this.job) throw new Error("No current job");
            if (!this.isStatusBelow(JobStatus.processing) && this.status != JobStatus.error ) throw new Error(`Preview requested on job with a status of ${this.status}`);

            //NOTE: Already checked this on step 2 ... do we really need it again?
            if (this.customParts.length == 0) {
                const confirmed = await confirm('You have not added any personalized parts. Are you sure you want to create a video with no custom parts?');
                if (!confirmed) {
                    //NOTE: Just abort...
                    //TODO: Should this redirect?
                    //router.push(`/video/${this.job.$id}/customize`); ?????
                    return false;
                }
            }

            if (!this.allTextApproved) {
                router.push(`/video/${this.job.$id}/customize`);
                const confirmed = await alerta('You have not set all text values. Please edit and set the text for each part of your video with a red "Set Text" button.', 'Text Not Set', 'Please Review'  );
                return false;
            }

            //TODO: This was to flush out the old Appwrite outputs system, but now that we're using Bunny this is not necessary
            // if (this.job.previewOutputId) {
            //     //NOTE: This job already had a preview, let's clear it out...
            //     console.log("Clearing old preview...");
            //     if (await removeOutput(this.job.previewOutputId))
            //         this.job.previewOutputId = null;
            // }

            this.job.status = JobStatus.preview;
            //TODO: Should the sort order ALSO be saved here??
            await this.update();

            return true;
    
        },
        resetStore() {
            this.loading = false;
            this.job = undefined;
            this.parts.total = 0;
            this.parts.documents = [];

            //TODO: The Piñia way ... but this doesn't work now that it's all being cached to localstore...
            //this.$reset();
        }
    },
    getters: { //Computed Properties for the Store
        //doubleCount: (state) => state.count * 2,
        customParts: (state) => _filterCustomParts(state.parts),
        editableTextParts: (state) => (state.parts.documents && state.parts.documents.filter((part: Part) => sequence.find((def: any) => def.part == part.part)?.inputs ) ) ?? [] as Part[],
        audioSource: (state) => { 
            const userProfile = useUserProfileStore(pinia);
            return state.job?.audio && userProfile.sources.files && userProfile.sources.files.find((source) => source.$id == state.job?.audio);
        },
        status: (state) => state.job?.status ?? "" as JobStatus,
        //NOTE: If locked, the contents of the job cannot be changed. User must unlock the job and cancel any previews. Final render cannot be canceled?
        locked: (state) => state.job?.status == JobStatus.preview,
        outroPart: (state) => state.parts.documents && state.parts.documents.find((p:Part) => p.part === PartType.outro),
        creditsPart: (state) => state.parts.documents && state.parts.documents.find((p:Part) => p.part === PartType.credit),
        creditsCost: (state) => Math.max(0,
            state.parts.documents && state.parts.documents.reduce( 
            (previousValue: number, part:Part) => 
                previousValue + (sequence.find((sequenceDef: any) => sequenceDef.part == part.part)?.cost ?? 0)
            , 0)
        ),
        previewApproved: (state) => state.job && state.job?.previewApproved,
        allTextApproved(): boolean { return this.editableTextParts.filter((p:Part) => !p.textApproved).length == 0 },
        routeToEdit: (state) => {            

            //NOTE: No job set, let's start one...
            if (!state.job) {
                console.log("Redirect #025");
                return "/video/start";
            }

            if (state.job.status == JobStatus.error) {
                console.log("Redirect #019");
                return `/video/${state.job.$id}/error`;
            } else if (state.job.status == JobStatus.ready || state.job.status == JobStatus.preview) {
                console.log("Redirect #022");
                return `/video/${state.job.$id}/preview`;
            } else if (state.job.status == JobStatus.pending || state.job.status == JobStatus.done) {
                console.log("Redirect #021");
                return `/share/${state.job.$id}`;
            } else if (_isStatusAbove(state.job.status, JobStatus.pending)) {
                console.log("Redirect #020");
              return `/video/${state.job.$id}/share`;
            } else if (_filterCustomParts(state.parts).length > 0) {
                console.log("Redirect #019");
                return `/video/${state.job.$id}/customize`;
            }

            console.log("Redirect #023");            
            return `/video/${state.job.$id}/personalize`;            
        },

    }
});
