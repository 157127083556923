<template>
    <ion-menu
        side="end"
        menu-id="app-menu-end"
        content-id="main-content"
        type="push"
        @ionDidOpen="emit('opened')"
        @ionDidClose="emit('closed')"
    >
        <ion-header>
            <ion-toolbar color="primary">
                <ion-title v-t="'components.menu_end.video_credits'"></ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>

            <p class="no-padding no-margin ion-padding-top" v-if="userProfile.registered">
                <ion-icon :icon="personOutline"></ion-icon>
                {{ userProfile.session?.name ? userProfile.session?.name : userProfile.session?.email}}                
            </p>

            <Credits @closed="closeMenu" class="no-padding-top"></Credits>

            <PayPalCart @complete="closeMenu"></PayPalCart>

            <div v-if="tokens.totalCartCredits > 0">

                <credits-display  
                    :value="(userProfile.credits  + tokens.totalCartCredits)" 
                    color="danger" v-if="!activeJob.job">
                    <template v-slot:start>
                        {{$t('components.menu_end.after_checkout')}}
                    </template>
                    <template v-slot:end>
                        <p><small v-t="'components.menu_end.available_credits'"></small></p>    
                    </template>
                </credits-display>
                
                <p v-if="activeJob.job && (userProfile.credits + tokens.totalCartCredits) >= activeJob.creditsCost">
                    After checkout you will have enough credits to finish your    
                    '{{ activeJob.job.title }}' 
                    video! ☺
                </p>
                <p v-if="activeJob.job && (userProfile.credits + tokens.totalCartCredits < activeJob.creditsCost)">
                    Which will still be 
                    {{activeJob.creditsCost - (userProfile.credits + tokens.totalCartCredits)}} 
                    credits short of enough to finish your    
                    '{{ activeJob.job.title }}'
                    video. 😕
                </p>
                    
            </div>

            <ion-card>
                <ion-card-header>
                    <h2 class="no-margin">Coming Soon!</h2>
                </ion-card-header>
                <ion-card-content>
                    <p class="share-the-fun">
                        Share the fun, and
                        send 🎁 <br/>
                        credits to anyone!
                    </p>
                </ion-card-content>
            </ion-card>
            
        </ion-content>
    </ion-menu>
</template>

<script lang="ts" setup>
import { menuController } from '@ionic/vue';

import PayPalCart from "@/components/PayPalCart.vue";
import Credits from "@/components/Credits.vue";
import CreditsDisplay from "@/components/CreditsDisplay.vue";

import { personOutline }  from "ionicons/icons";

import { useUserProfileStore } from '@/store/userProfileStore';
import { useTokenStore, StoreItem } from "@/store/tokenStore";
import { useActiveJobStore } from '@/store/activeJobStore';

const userProfile = useUserProfileStore();
const activeJob = useActiveJobStore();
const tokens = useTokenStore();

const emit = defineEmits(["opened", "closed"]);

function closeMenu() {
    menuController.close();
}

let inCheck = false;

//TODO: This won't work anymore...
// function debugAccount() {
//     //NOTE: This jacked "double click" implementation is because iOS doesn't support the double click event
//     if (!inCheck) {
//         inCheck = true;
//         //NOTE: Time will automatically clear our check flag if no other click is seen by then...
//         setTimeout(()=>inCheck = false,500);
//     } else {        
//         //TODO: Tempoary debugging and testing...
//         userProfile.credits = tokens.totalCartCredits;
//         processPaymentPPv1([["id","intent","status","purchase_units","payer","create_time","update_time","links","a|0|1|2|3|4|5|6|7","73A06015AH588200A","CAPTURE","COMPLETED","reference_id","amount","payee","description","custom_id","items","shipping","payments","a|C|D|E|F|G|H|I|J","memory-tree-credits","currency_code","value","breakdown","a|M|N|O","USD","30.00","item_total","handling","insurance","shipping_discount","a|S|I|T|U|V","a|M|N","o|X|Q|R","0.00","o|X|Q|Z","o|W|Y|a|a|a|a","o|P|Q|R|b","email_address","merchant_id","a|d|e","testing+business@studiob4.com","8RETHJ2968TPJ","o|f|g|h","Memory Tree Video Credits","633c8fc343b01e81e7be|10","name","unit_amount","tax","quantity","sku","a|l|m|n|o|F|p","10","1","10 Credit Pack","Memory-Tree-10","o|q|r|Y|a|s|t|u","a|v","address","a|l|x","full_name","a|z","John Doe","o|10|11","address_line_1","admin_area_2","admin_area_1","postal_code","country_code","a|13|14|15|16|17","1 Main St","San Jose","CA","95131","US","o|18|19|1A|1B|1C|1D","o|y|12|1E","captures","a|1G","final_capture","seller_protection","a|0|2|D|1I|1J|5|6","13074149679540311","b|T","dispute_categories","a|2|1N","ELIGIBLE","ITEM_NOT_RECEIVED","UNAUTHORIZED_TRANSACTION","a|1Q|1R","o|1O|1P|1S","2022-10-13T08:16:51Z","o|1K|1L|B|Y|1M|1T|1U|1U","a|1V","o|1H|1W","o|K|L|c|i|j|k|w|1F|1X","a|1Y","payer_id","a|l|d|1a|x","given_name","surname","a|1c|1d","John","Doe","o|1e|1f|1g","testing+personal@studiob4.com","YDR5J4WSZHTUS","a|17","o|1k|1D","o|1b|1h|1i|1j|1l","2022-10-13T08:16:12Z","href","rel","method","a|1o|1p|1q","https://api.sandbox.paypal.com/v2/checkout/orders/73A06015AH588200A","self","GET","o|1r|1s|1t|1u","a|1v","o|8|9|A|B|1Z|1m|1n|1U|1w"],"1x"]); 
//         tokens.clear()
//         inCheck = false;
//     }
// }

</script>

<style scoped>
ion-menu {
    --background: linear-gradient(rgba(40, 130, 130, 0.5), rgba(40, 130, 130, 0.38)),
        #ffffff no-repeat center center / cover;
}
.share-the-fun {
    font-size: 1.3em;
}
</style>
