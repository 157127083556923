import { createApp, provide } from "vue";
import { IonicVue } from "@ionic/vue";

import { autoAnimatePlugin } from "@formkit/auto-animate/vue";

import App from "./App.vue";
import router from "./router";

import i18n from "../i18n";

import { createPinia } from "pinia";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.scss";

import "./theme/fiesta-gatitos.scss";
import "./theme/memory-tree.scss";
//TODO: Does VJLoopa need a variables scss?

import pinia from "@/store";

//import sequence from '@/assets/memory-tree/sequence.json';
//import { product, sequence } from "@/product";

import {errorHandler} from "@/sdk";

//TODO: Can't get this to work
// import Vue3Tour from 'vue3-tour';
// import 'vue3-tour/dist/vue3-tour.css'

import appStartup from "@/version";
appStartup();


(window as any).debug_mode_active = import.meta.env.MODE != "production";


import { useUserProfileStore } from "@/store/userProfileStore"; 
//TODO: This was in mounted, where it might work better? 12.6 #BREAKING?





export const app = createApp(App)
  .use(pinia)
  .use(IonicVue)
  .use(i18n)
  //  .use(Vue3Tour) //TODO: Can't get this to work...
  .use(autoAnimatePlugin);

  //NOTE: We need to kick ofF the user startup BEFORE the router loads...
  const userProfile = useUserProfileStore();
  userProfile.startup().then(() => {
    app
      .use(router); //NOTE: Loading router LAST!!!
  });

  
//NOTE: Globally load all the ionic components...
import * as IonicComponents from '@ionic/vue';
Object.keys(IonicComponents).forEach(key => {
	if (/^Ion[A-Z]\w+$/.test(key)) {
		app.component(key, (IonicComponents as any)[key]);
	}
});

import RegisterMessageComponent from  "@/components/RegisterMessageComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

app.component("RegisterMessageComponent", RegisterMessageComponent);
app.component("HeaderComponent", HeaderComponent);
app.component("FooterComponent", FooterComponent);

router.isReady().then(() => {
    app.mount("#app");
	console.log("Ready!");
});



/* ERROR HANDLING VIA AIRBRAKE */

import { Notifier } from '@airbrake/browser';

console.log("MODE", import.meta.env.MODE);

var airbrake = new Notifier({
  environment: import.meta.env.MODE ?? 'development',
  projectId: import.meta.env.VITE_AIRBRAKE_PROJECT_ID, //MEMORY TREE - AIRBRAKE
  projectKey: import.meta.env.VITE_AIRBRAKE_KEY
});

app.config.errorHandler = function (err, vm, info) {
  console.error("ERROR", err, info);
  airbrake.notify({
    error: err,
    params: {info: info}
  });
  //errorHandler(err as any);
}

//window.onerror("TestError: This is a test", "path/to/file.js", 123);


// app.use(IonicVue, {
//   platform: {
//     /** The default `desktop` function returns false for devices with a touchscreen.
//      * This is not always wanted, so this function tests the User Agent instead.
//      **/
//     desktop: (win:any) => {
//       console.log("DESKTOP CHECK", win.navigator.userAgent);
//       const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent);
//       return !isMobile;
//     },
//     mobile: (win:any) => {
//       console.log("MOBILE CHECK", win.navigator.userAgent);
//       const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent);
//       return isMobile;
//     },
//   },
// });



