<template>
    <!-- <ion-header :translucent="true">
    <ion-toolbar>
      <ion-title>Home</ion-title>
    </ion-toolbar>
  </ion-header> -->
    <ion-header :translucent="true">
        <ion-toolbar>
            <!-- <ion-buttons slot="start">
                <ion-back-button :v-show="showBackButton" slot="primary" default-href="/"></ion-back-button>
            </ion-buttons> -->

            <ion-buttons slot="start" :class="{'ion-padding': !isSmallWindow, 'ion-no-padding': isSmallWindow}">

                <ion-button
                    @click="openMenu"
                    fill="clear"
                    :shape="('default') as any"
                    size="large"
                    :strong="true"
                    class="uniform-padding big-button"
                    v-if="!menuIsOpen">
                    <ion-icon :icon="menu" class=""></ion-icon>
                </ion-button>
                <ion-button
                    @click="openMenu"
                    fill="clear"
                    :shape="('default') as any"
                    size="large"
                    :strong="true"
                    class="uniform-padding big-button"
                    v-if="menuIsOpen">
                    <ion-icon :icon="close" class=""></ion-icon>
                </ion-button>

            </ion-buttons>

            <ion-buttons :collapse="false" class="ion-justify-content-center">
                <ion-nav-link router-link="/" fill="clear" class="clickable">
                    <!-- <img src="/img/logo_words.svg" class="logo" alt="A unique audio-video presentation of your memories" /> -->
                    <svg class="svg-logo_words-dims word-logo">
                        <use :xlink:href="getProductPath('/sprite.svg#logo_words')"></use>
                    </svg>

                </ion-nav-link>
            </ion-buttons>

            <ion-buttons slot="end" :class="{'ion-padding': !isSmallWindow}">

                <ion-button
                    router-link="/videos"                    
                    fill="clear"
                    :shape="('default') as any"
                    size="large"
                    :strong="true"
                    class="uniform-padding big-button"
                    v-if="!cartIsOpen">
                    
                    <div class="cart-button-icon-container">
                        <ion-icon :icon="filmOutline"></ion-icon>
                        <div class="video-counts" :class="{'badge': (jobs.unfinishedVideos.length || jobs.finishedVideos.length), 'badges': (jobs.unfinishedVideos.length && jobs.finishedVideos.length)}" v-if="(jobs.unfinishedVideos.length || jobs.finishedVideos.length)">
                            <ion-badge 
                            class="cart-button-badge" color="warning" 
                            v-if="jobs.unfinishedVideos.length > 0">{{ jobs.unfinishedVideos.length }}
                            </ion-badge>
                            <span v-if="(jobs.unfinishedVideos.length && jobs.finishedVideos.length)">/</span>
                            <ion-badge 
                            class="cart-button-badge" style="color: black;" color="success" 
                            v-if="jobs.finishedVideos.length > 0">{{ jobs.finishedVideos.length }}
                        </ion-badge>
                        </div>
                    </div>

                </ion-button>

                <ion-button
                    @click="openCart"
                    fill="clear"
                    :shape="('default') as any"
                    size="large"
                    :strong="true"
                    class="uniform-padding big-button"
                    v-if="!cartIsOpen && totalItems == 0">
                    
                    <div class="cart-button-icon-container">
                        <ion-icon :icon="walletOutline"></ion-icon>
                        <div class="badge">
                            <ion-badge class="credits-badge" color="danger" v-if="userProfile.session">{{ totalCredits }}</ion-badge>
                        </div>
                    </div>

                </ion-button>

                <!-- <ion-button href="/about"><ion-icon :icon="menu" /> About</ion-button>
                <ion-button href="/pricing"><ion-icon :icon="menu" /> Pricing</ion-button>
                <ion-button href="/register"><ion-icon :icon="menu" /> Register</ion-button>
                <ion-button href="/login"><ion-icon :icon="menu" /> Login</ion-button> -->
                <!-- <LanguageSelector /> -->
                <ion-button
                    @click="openCart"
                    fill="clear"
                    :shape="('default') as any"
                    size="large"
                    :strong="true"
                    class="uniform-padding big-button"
                    v-if="cartIsOpen">
                    <ion-icon :icon="close"></ion-icon>
                </ion-button>
                <ion-button
                    @click="openCart"
                    :fill="totalItems > 0 ? 'solid' : 'clear'"
                    :shape="(totalItems > 0 ? 'round' : 'default' ) as any"
                    :color="totalItems > 0 ? 'warning' : ''"
                    size="large"
                    :strong="true"
                    class="uniform-padding cart-button-text"
                    v-if="!cartIsOpen && totalItems > 0">
                    <ion-icon :icon="cartOutline" class="big-button cart-button-icon" v-if="totalItems == 0" slot="start"></ion-icon>
                    <template v-if="totalItems > 0" slot="start">
                        <div class="cart-button-icon-container" >
                            <ion-icon :icon="cartOutline" class="big-button cart-button-icon"></ion-icon>
                            <ion-badge class="cart-button-badge">{{ totalItems }}</ion-badge>
                        </div>
                        &nbsp; CHECKOUT
                        <ion-icon :icon="caretForwardOutline"></ion-icon>
                    </template>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
</template>

<script setup lang="ts">
import {
    menuController,
} from "@ionic/vue";
import { computed, ref, inject } from "vue";
import { home } from "ionicons/icons";
import LanguageSelector from "@/components/LanguageSelector.vue";

import { getProductPath } from "@/product";

import { menu, cartOutline, close, caretForwardOutline, walletOutline, filmOutline, videocamOutline } from "ionicons/icons";

import { useUserProfileStore } from "@/store/userProfileStore";

import {useTokenStore, StoreItem} from "@/store/tokenStore";
import {useJobStore} from "@/store/jobStore";

const jobs = useJobStore();

const isSmallWindow = computed(() => window.matchMedia('(min-width: 600px)'));

const props = defineProps({
    title: {
        type: String,
    },
    showBackButton: {
        type: Boolean,
        default: false,
    },
});

const userProfile = useUserProfileStore();
const tokens = useTokenStore();

const vertical_name = "StudioB4.com";

const itemsFiltered = computed(() => {
    return tokens.items.filter((item: any) => item.quantity > 0);
});

const totalItems = computed(() => {
    let total = 0;
    for (let iTotal = 0; iTotal < tokens.items.length; iTotal++) {
        total = total + tokens.items[iTotal].quantity;
    }
    return total;
});

const openCart = () => {
    menuController.enable(true, "end");
    menuController.open("end");
};

const openMenu = () => {
    menuController.enable(true, "start");
    menuController.open("start");
};

menuController.enable(true, "end");
menuController.enable(true, "start");
//menuController.close("end");

const cartIsOpen = computed(() => menuEndOpen.value); // menuController.isOpen("end"));
const menuEndOpen = ref(inject("menuEndOpen", false));

const menuIsOpen = computed(() => menuStartOpen.value); // menuController.isOpen("start"));
const menuStartOpen = ref(inject("menuStartOpen", false));

const totalCredits = computed(() => userProfile.credits);


</script>

<style scoped scss>

.video-counts { display: inline-block;}

ion-button .badge {
    display: inline-block;
    font-size: 1em;
    margin-left: -1em;
}
ion-button .badges {
    display: inline-block;
    font-size: 1em;
    margin-left: -1.5em;
}

ion-button .badge ion-badge,
ion-button .badges ion-badge {
    font-size: .5em;
}

ion-buttons.buttons-last-slot {
    margin-right: 2em;
}

@media screen and (max-width: 600px) {

    ion-buttons.buttons-last-slot {
        margin-right: .25em;
    }
    
}

</style>
