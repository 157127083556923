import { createRouter, createWebHistory } from "@ionic/vue-router";

//import Home from "@/views/Home.vue";
// import Home from "@/views/Home.vue";
//import HomeExperimental from "@/views/HomeExperimental.vue";
//import Pricing from "@/views/Pricing.vue";
//import Register from "@/views/Register.vue";
//import Login from "@/views/Login.vue";
// import About from "@/views/About.vue";
// import Store from "@/views/Store.vue";
// import Videos from "@/views/Videos.vue";


import DomainError from "@/views/DomainError.vue";
import { home, triangle } from "ionicons/icons";
import i18n from "../../i18n";
import { useUserProfileStore } from "@/store/userProfileStore";
import { useJobStore } from "@/store/jobStore";
import { useActiveJobStore } from "@/store/activeJobStore";
import { jobsCollectionId, getJobById, JobStatus, getShareById } from "@/sdk";
import { RouteAction, RouteInfo } from "@ionic/vue-router/dist/types/types";

import { RouteRecordRaw, RouteLocationNormalized, NavigationGuardNext, RouteMeta } from "vue-router";

import routeMetadata from "./route-meta-en.json";

import { toast, confirm } from "@/utility";

import pinia from "@/store";

import { product } from "@/product";


async function checkForActiveJob(to: RouteLocationNormalized, from: RouteLocationNormalized) {
	console.log("Check for Active Job", to);
	const activeJob = useActiveJobStore();
	if (activeJob?.job?.$id != to.params.id) {		
		try {
			const newJob = await getJobById(to.params.id as string);
			if (newJob) {
				await activeJob.loadJob(newJob);
			}
		} catch (err) {
			console.log("ERROR", err);
			console.log("Redirect #018");
			return {name: "login" };
		}
		//TODO: Else, throw error? Probably not the current user's job?
	}

	if (activeJob.isStatusAbove(JobStatus.pending) && activeJob.status != JobStatus.error) {
		return { name: "share", params: to.params };
	}

	//NOTE: If the path is to personalize/upload, and uploads are not supported, redirect to customize
	if (to.name == "video.personalize" && !product.allow_uploads) {
		//TODO: If the product definition does not allow uploads, personalize goes straight to customize
		return { name: "video.customize", params: to.params };
	}
	
	return;
}

//TODO: Can these definitions, specifically the meta data, be pulled in from a JSON or TS file?
//const routes: RouteRecordRaw[] = require('routes.json');

const replacementPattern = /{{(.*)}}/g;

function replaceValue(source: string, replacement: string) {
	const replacementValue = replacement.replace("{{", "").replace("}}", "");

	const env = import.meta.env;

	// console.log("Env", env);
	// console.log("replacementValue", replacementValue, env[replacementValue]);

	return source.replace(replacement, env[replacementValue]);
}

function replaceValues(source: string | Object) {

	//console.log("Typeof source", typeof source);

	if (typeof source === "object") {
		const newObject = {} as any;
		for (const [key, value] of Object.entries(source)) {
			//console.log("> Item", key, value);
			const replacements = value.match(replacementPattern);
			//console.log("Replacements", replacements);		
			let newValue = value;
			for (const replacement of replacements) {
				newValue = replaceValue(newValue, replacement);
			}
			newObject[key] = newValue;
		}
		//console.log("Returning newObject", newObject);
		return newObject;
	}

	const replacements = source.match(replacementPattern);
	//console.log("Replacements", replacements);		
	let newStringValue = source;
	if (replacements) {
		for (const replacement of replacements) {
			newStringValue = replaceValue(newStringValue, replacement);
		}
	}
	return newStringValue;
}

function getMetadata(routeKey: string) {

	let useKey = routeKey;
	let myMetadataReturn;

	//console.log("Typeof Metadata", typeof (routeMetadata as any)[useKey]);

	//NOTE: Check to see that the key provided isn't a pointer to another key... If it is, use that instead
	if (typeof (routeMetadata as any)[useKey] === "string") {
		//console.log("Treating as string, using new route key");
		useKey = (routeMetadata as any)[useKey];
	}
	myMetadataReturn = (routeMetadata as any)[useKey];

	//console.log("Values",Object.entries(myMetadataReturn));

	const populatedMetadata = {} as any;
	for (const [key, value] of Object.entries(myMetadataReturn)) {
		//console.log("Analyzing keyvalue", key, value);
		populatedMetadata[key] = replaceValues(value as Object | string);
	}

	console.log("Route Metadata for " + ( routeKey == useKey ? routeKey : `${routeKey} -> ${useKey}`), populatedMetadata);
	return populatedMetadata; // as RouteMeta;
}

const routes: RouteRecordRaw[] = [
	{
		path: "/",
		redirect: "/home",
	},
	{
		path: "/home",
		name: "home",
		component: () => import("@/views/Home.vue"),
		// meta: routeMetadata?.home
		meta: {
			title: import.meta.env.VITE_APP_TITLE_60,
			icon: home,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
	},	
	{
		path: "/home-exp",
		name: "home-experimental",
		component: () => import("@/views/HomeExperimental.vue"),
		//component: HomeExperimental,
		meta: {
			title: import.meta.env.VITE_APP_TITLE_60,
			icon: home,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
	},
	{
		path: "/about",
		name: "about",
		component: () => import("@/views/About.vue"),
		meta: getMetadata("about")
		// meta: {
		// 	title: `About ${import.meta.env.VITE_APP_TITLE}`,
		// 	icon: triangle,
		// 	metaPropertyTags: {
		// 		"og:title": import.meta.env.VITE_APP_TITLE_60,
		// 		"og:image": import.meta.env.VITE_APP_IMAGE,
		// 		"og:description": import.meta.env.VITE_APP_DESCRIPTION,
		// 	},
		// 	metaNameTags: {
		// 		description: import.meta.env.VITE_APP_DESCRIPTION,
		// 	},
		// },
	},
	{
		path: "/store",
		name: "store",
		alias: ["/buy", "/pricing"],
		component: () => import("@/views/Store.vue"),
		meta: {
			title: `Store | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
	},
	{
		path: "/features",
		name: "features",
		//alias: "/buy",
		component: () => import("@/views/Features.vue"),
		meta: {
			title: `Features | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
	},
	{
		path: "/payment",
		name: "payment",
		alias: "/pay",
		component: () => import("@/views/Store.vue"),
		meta: {
			title: `Thank You! | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
	},
	{
		path: "/gallery",
		name: "gallery",
		alias: ["/examples", "/samples"],
		component: () => import("@/views/Gallery.vue"),
		meta: {
			title: `Sample Gallery | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
	},
	{
		path: "/start",
		name: "start",
		alias: ["/free", "/start-free"],
		component: () => import("@/views/Start.vue"),
		meta: {
			title: `How to make and share a FREE video! | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
	},

	{
		path: "/videos",
		name: "videos",
		alias: "/jobs",
		component: () => import("@/views/Videos.vue"),
		meta: {
			title: `Video Projects | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
			//requiresAuth: true,
		},
		beforeEnter: (to, from) => {
			// reject the navigation
			const userProfile = useUserProfileStore(pinia);
			//NOTE: If the user is already registered, they should be redirected to /profile;
			if (userProfile.session) {
				const jobs = useJobStore(pinia);
				console.log("REFRESHING JOBS LIST...");
				jobs.loadJobs();
			}
			return true;
		},
	},

	{
		path: "/profile",
		name: "profile",
		component: () => import("@/views/Profile.vue"),
		meta: {
			title: `Profile | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
	},

	// tabs / Tree
	{
		path: "/video/",
		component: () => import("@/views/Tabs.vue"),
		children: [
			{
				path: "",
				name: "videostart",
				redirect: "/video/personalize",
			},
			{
				path: "start",
				name: "video.start",
				component: () => import("@/views/TabStep1.vue"),
			},
			// {
			//     path: "customize",
			//     component: () => import("@/views/TabStep2.vue"),
			// },
			// {
			//     path: "audio",
			//     component: () => import("@/views/TabStep3.vue"),
			// },
			// {
			//     path: "preview",
			//     component: () => import("@/views/TabStep4.vue"),
			//     beforeEnter: () => {
			//         //const activeJob = useActiveJobStore();
			//         //activeJob.checkJobStatus();
			//         //setTimeout(activeJob.checkJobStatus, 30*1000);
			//     }
			// },
			// {
			//     path: "error",
			//     component: () => import("@/views/TabError.vue"),
			// },
			//NOTE: Tabstep 4 is really just for context -- it's not treated as a tab
		],
	},
	{
		path: "/video/:id",
		component: () => import("@/views/Tabs.vue"),
		children: [
			//TODO: It would be nice if THIS could be the share location...?
			{
				path: "",
				name: "video",				
				redirect: (route) => { 
					//TODO: This should be where it goes if the user is the owner and video is not finished...
					return `/video/${route.params.id}/personalize`;
					//TODO: This is where it should go if the user is NOT the owner...
					//redirect: (route) => "/video/" + route.params.id + "/personalize",
				}				
			},
			{
				path: "personalize",
				name: "video.personalize",
				component: () => import("@/views/TabStep1.vue"),
				beforeEnter: checkForActiveJob,
				meta: {
					requiresAuth: true,
				}
			},
			{
				path: "customize",
				name: "video.customize",
				component: () => import("@/views/TabStep2.vue"),
				beforeEnter: checkForActiveJob,
				meta: {
					requiresAuth: true,
				}
			},
			{
				path: "audio",
				name: "video.audio",
				component: () => import("@/views/TabStep3.vue"),
				beforeEnter: checkForActiveJob,
				meta: {
					requiresAuth: true,
				}
			},
			{
				path: "preview",
				name: "video.preview",
				component: () => import("@/views/TabStep4.vue"),
				meta: {
					requiresAuth: true,
				},
				beforeEnter: async (to, from) => {
					
					const check = await checkForActiveJob(to, from);					
					if (check) return check; 					

					const activeJob = useActiveJobStore();

					if (activeJob.status == JobStatus.new) {
						//TODO: Start the preview generation...
						const start = await activeJob.startPreview();
						if (!start)
							return {name: "video.customize", params: to.params }
					} 

					return true; //NOTE: Clear to proceed with the route
				},
			},
			{
				path: "error",
				name: "video.error",
				component: () => import("@/views/TabError.vue"),
				beforeEnter: checkForActiveJob
			},
			//NOTE: Tabstep 4 is really just for context -- it's not treated as a tab
		],
	},
	{
		path: "/video/:id/share",
		name: "share",
		component: () => import("@/views/Share.vue"),
		meta: {
			title: "Shared Video | Memory Tree Video ", //TODO: Use Video Share Title
			icon: triangle,
			metaPropertyTags: {
				"og:title": "CUSTOM TITLE", //import.meta.env.VITE_APP_TITLE_60, //TODO: Use Video Share Title
				"og:image": "/IMAGE-TEST.PNG", //import.meta.env.VITE_APP_IMAGE, //TODO: Use Video Thumbnail
				"og:description": import.meta.env.VITE_APP_DESCRIPTION, //TODO: Use Video Share Message??
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION, //TODO: Use Video Share Message??
				robots: "noindex",
			},
		},
        // beforeRouteUpdate(to: any, from: any) {
		//     to.meta.title = "TESTING - UPDATED?";
		//     (to.meta.metaPropertyTags as any)['og:title'] = "OG TITLE";
        //     return to;
		// },
		// beforeEnter: (to: any, from: any) => {
		//     //TODO: CANNOT USE ACTIVE JOB FOR THIS!
		//     //const activeJob = useActiveJobStore();
		//     //activeJob.checkJobStatus();
		//     //setTimeout(activeJob.checkJobStatus, 30*1000);
		//     to.meta.title = "TESTING - UPDATED?";
		//     (to.meta.metaPropertyTags as any)['og:title'] = "OG TITLE";
		// }
	},



	{
		path: "/source/:id",
		component: () => import("@/views/PhotoEdit.vue"),
		name: "source.edit",
		meta: {
			requiresAuth: false,
		},
		//beforeEnter: checkForActiveJob,
	},


	// {
	//     path: "/pricing",
	//     name: "pricing",
	//     component: Pricing,
	//     meta: {
	//         title: "Pricing",
	//         subtitle: "Pricing page subtitle",
	//         description: "This is a description",
	//         icon: triangle,
	//     },
	// },
	{
		path: "/register",
		name: "register",
		component: () => import("@/views/Register.vue"),
		meta: {
			title: `Register | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
		beforeEnter: (to, from) => {
			// reject the navigation
			const userProfile = useUserProfileStore(pinia);
			//NOTE: If the user is already registered, they should be redirected to /profile;
			if (userProfile.session && userProfile.registered) {
				console.log("Redirect #054");
				return "/profile";
			}
			return true; //NOTE: Clear to proceed with the route
		},
	},
	{
		path: "/profile/security",
		name: "security",
		component: () => import("@/views/Security.vue"),
		meta: {
			title: `Security Settings | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			// metaPropertyTags: {
			//     'og:title': import.meta.env.VITE_APP_TITLE_60,
			//     'og:image': import.meta.env.VITE_APP_IMAGE,
			//     'og:description': import.meta.env.VITE_APP_DESCRIPTION,
			// },
			// metaNameTags: {
			//     'description': import.meta.env.VITE_APP_DESCRIPTION,
			//    }
			meta: {
				requiresAuth: true,
			}
		},
		//    beforeEnter: (to, from) => {
		//     // reject the navigation
		//     const userProfile = useUserProfileStore();
		//     //NOTE: If the user is already registered, they should be redirected to /profile;
		//     // if (!userProfile.session) {
		//     //     console.log("Redirect #055");
		//     //     return '/home';
		//     // }
		//     //return true;
		//   },
	},
	{
		path: "/login/:email?",
		name: "login",
		component: () => import("@/views/Login.vue"),
		meta: {
			title: `Login | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
		beforeEnter: (to, from) => {
			const userProfile = useUserProfileStore(pinia);
			//console.log("***************>> LOGIN USER CHECK IS", userProfile.session);
			if (userProfile.isLoggedIn) {
				//NOTE: User is already logged in... redirect to home, and show a notice.
				toast("You are already logged in. Sign out first.", 'warning');
				return { name: "home" };
			}
			//return next({name:"videos"});
			return true; //NOTE: Clear to proceed with the route
		}
	},
	{
		path: "/verify/:userId/:email", //TODO: Appwrite's verification system is tied to the API, and limits e-mail format -- provide our own
		name: "verify",
		component: () => import("@/views/Verify.vue"),
		meta: {
			title: `Verify E-mail Address | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
			requiresAuth: true,
		},
		beforeEnter: async (to, from) => {
			//TODO: This guard can't be used to test for the correct user, because the user hasn't been loaded yet... Try page level
		}
	},
	{
		path: "/recover",
		name: "recover",
		component: () => import("@/views/Recover.vue"),
		meta: {
			title: `Reset your Password | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
	},
	{
		path: "/reset",
		name: "reset",
		component: () => import("@/views/Recover.vue"),
		// meta: {
		// 	title: `Reset your Password | ${import.meta.env.VITE_APP_TITLE}`,
		// 	icon: triangle,
		// 	metaPropertyTags: {
		// 		"og:title": import.meta.env.VITE_APP_TITLE_60,
		// 		"og:image": import.meta.env.VITE_APP_IMAGE,
		// 		"og:description": import.meta.env.VITE_APP_DESCRIPTION,
		// 	},
		// 	metaNameTags: {
		// 		description: import.meta.env.VITE_APP_DESCRIPTION,
		// 	},
		// },
		beforeEnter: async (to, from) => {
			const userProfile = useUserProfileStore(pinia);
			await userProfile.logoff();
			console.log("LOGGED OFF");
		}
	},
	{
		path: "/privacy",
		name: "privacy",
		component: () => import("@/views/Privacy.vue"),
		meta: {
			title: `Privacy Policy | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
	},
	{
		path: "/terms",
		name: "terms",
		component: () => import("@/views/Terms.vue"),
		meta: {
			title: `Terms and Conditions | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
	},
	{
		path: "/faq",
		//alias: "/faq",
		name: "faq",
		component: () => import("@/views/FAQ.vue"),
		meta: {
			title: `Frequently Asked Questions & Answers | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
	},
	{
		path: "/how-to",
		//alias: "/faq",
		name: "howto",
		component: () => import("@/views/HowToCreateTouchingVideo.vue"),
		meta: {
			title: `How to Create a Touching Video People Will Love | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
	},
	{
		path: "/contact",
		//alias: "/faq",
		name: "contact",
		component: () => import("@/views/Contact.vue"),
		meta: {
			title: `Contact Support | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
	},
	{
		path: "/error",
		name: "error",
		component: () => import("@/views/Error.vue"),
		meta: {
			title: `Something went wrong... | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
	},
	{
		path: "/no-network",
		alias: "/network-error",
		name: "no-network",
		component: () => import("@/views/NoNetwork.vue"),
		meta: {
			title: `Network Problem | ${import.meta.env.VITE_APP_TITLE}`,
			icon: triangle,
			metaPropertyTags: {
				"og:title": import.meta.env.VITE_APP_TITLE_60,
				"og:image": import.meta.env.VITE_APP_IMAGE,
				"og:description": import.meta.env.VITE_APP_DESCRIPTION,
			},
			metaNameTags: {
				description: import.meta.env.VITE_APP_DESCRIPTION,
			},
		},
	},
	// {
	//     path: "/domain-error",
	//     name: "domainError",
	//     component: DomainError,
	//     meta: {
	//         title: "Domain Error",
	//         subtitle: "Domain Error page subtitle",
	//         description: "This is a description",
	//         icon: triangle,
	//     },
	// },

	// tabs
	// {
	//     path: "/tabs/",
	//     component: () => import("@/views/Tabs.vue"),
	//     children: [
	//         {
	//             path: "",
	//             redirect: "/tabs/tab1",
	//         },
	//         {
	//             path: "tab1",
	//             component: () => import("@/views/Tab1.vue"),
	//         },
	//         {
	//             path: "tab1/:id",
	//             component: () => import("@/views/Tab1Parameter.vue"),
	//             props: true,
	//         },
	//         {
	//             path: "tab2",
	//             component: () => import("@/views/Tab2.vue"),
	//         },
	//         {
	//             path: "tab3",
	//             // beforeEnter: (to, from, next) => {
	//             //   next({ path: '/tabs/tab1' });
	//             // },
	//             component: () => import("@/views/Tab3.vue"),
	//         },
	//         {
	//             path: "tab4",
	//             // beforeEnter: (to, from, next) => {
	//             //   next({ path: '/tabs/tab1' });
	//             // },
	//             component: () => import("@/views/Tab4.vue"),
	//         },
	//     ],
	// },
	{
		path: "/:pathMatch(.*)",
		name: "/404",
		//name: '404', 
		component: () => import("@/views/NotFound.vue"),
	},
];




const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes,
	//TODO: Problem is tab pages don't scroll back to top when tab is changed
	// scrollBehavior() {
	//     //return { x: 0, y: 0 }
	//     window.scrollTo(0, 0);
	// }
	scrollBehavior(to, from, savedPosition) {
		// always scroll to top
		return { top: 0 };
	},
});

// This callback runs before every route change, including on page load.
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
	//console.log("SETTING META TAGS");

	if (to.name != "login" && to.meta?.requiresAuth ) {

		const userProfile = useUserProfileStore(pinia);

		//NOTE: This route requires authentication. Check for a user...
		if (!userProfile.isLoggedIn && !userProfile.isTemporaryUser) {
			console.log("**********>>> Router User-check is", userProfile.session, to);
		
			console.log("Route To Requires Auth - Redirecting to Login",to);

			toast("Authentication required. Please sign in.", 'danger');
			
			return next({ name: "login" });
		}

	}


	// This goes through the matched routes from last to first, finding the closest route with a title.
	// e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
	// `/nested`'s will be chosen.
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r:RouteRecordRaw) => r.meta && (typeof r.meta === "function" ? (r.meta as any)(to).title : r.meta.title));

	if (nearestWithTitle && typeof nearestWithTitle.meta === "function") {
		nearestWithTitle.meta = (nearestWithTitle.meta as any)(to);
	}
	
	// Find the nearest route element with meta tags.
	console.log("TO MATChED", to.matched);
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r:RouteRecordRaw) => r.meta && (typeof r.meta === "function" ? (r.meta as any)(to).metaPropertyTags : r.meta.metaPropertyTags));

	if (nearestWithMeta && typeof nearestWithMeta.meta === "function") {
		nearestWithMeta.meta = (nearestWithMeta.meta as any)(to);
	}
	
	const previousNearestWithMeta = from.matched
		.slice()
		.reverse()
		.find((r:RouteRecordRaw) => r.meta && (typeof r.meta === "function" ? (r.meta as any)(to).metaPropertyTags : r.meta.metaPropertyTags));

	if (previousNearestWithMeta && typeof previousNearestWithMeta.meta === "function") {
		previousNearestWithMeta.meta = (previousNearestWithMeta.meta as any)(to);
	}

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) {
		document.title =
			typeof nearestWithTitle.meta.title === "function"
				? nearestWithTitle.meta.title(to)
				: (nearestWithTitle.meta.title as string);
	} else if (previousNearestWithMeta) {
		document.title =
			typeof previousNearestWithMeta.meta.title === "function"
				? previousNearestWithMeta.meta.title(to)
				: (previousNearestWithMeta.meta.title as string);
	}

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode?.removeChild(el));

	console.log("nearestWithMeta", nearestWithMeta);

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) return next();

	//NOTE: MetaPropertyTags...
	// Turn the meta tag definitions into actual elements in the head.
	Object.entries(nearestWithMeta.meta.metaPropertyTags as {})
		.map((tagDef) => {
			const tag = document.createElement("meta");

			//Object.keys(tagDef).forEach(key => {
			tag.setAttribute("property", tagDef[0] as string);
			tag.setAttribute("content", tagDef[1] as string);
			//});

			// We use this to track which meta tags we create so we don't interfere with other ones.
			tag.setAttribute("data-vue-router-controlled", "");

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach((tag) => document.head.appendChild(tag));

	//NOTE: MataNameTags...
	Object.entries(nearestWithMeta.meta.metaNameTags as {})
		.map((tagDef) => {
			const tag = document.createElement("meta");

			//Object.keys(tagDef).forEach(key => {
			tag.setAttribute("name", tagDef[0] as string);
			tag.setAttribute("content", tagDef[1] as string);
			//});

			// We use this to track which meta tags we create so we don't interfere with other ones.
			tag.setAttribute("data-vue-router-controlled", "");

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach((tag) => document.head.appendChild(tag));

	console.log("Router Done.");
	return next();
});

export default router;
