<template>
	<div class="ion-padding" v-show="orderTotal > 0">
		<h2 class="no-padding no-margin">CHECKOUT</h2>
		<ion-list>
			<!-- <ion-item v-if="orderTotal === 0">Your cart is empty. Add an item to start your order.</ion-item> -->

			<ion-item v-for="item in itemsFiltered" :key="item.sku" v-auto-animate>
				<span slot="start" class="itemcol">
					{{ item.quantity }} <ion-icon :icon="close" color="medium"></ion-icon> {{ item.description }}<br />
					<small class="individual-cost">${{ item.unit_amount.value }}ea</small>
					<small class="individual-cost" v-if="item.quantity > 1"> 
						= ${{ (Number(item.unit_amount.value) * item.quantity).toLocaleString() }}
					</small>
				</span>
				<!-- <span slot="primary"></span> -->
				<span slot="end">
					<ion-button @click="removeItem(item)" fill="clear">
						<ion-icon :icon="close"></ion-icon>
					</ion-button>
				</span>
			</ion-item>
		</ion-list>

		<ion-item v-if="orderTotal > 0" class="total-label">
			<span slot="start"> TOTAL: </span>
			<span slot="end" class="total-dollar">$ {{ orderTotal.toLocaleString() }}</span>
		</ion-item>

        <div class="paypal-buttons">
            <div ref="paypal" v-show="orderTotal > 0" class="ion-padding-top"></div>
            <div class="warning" @click="warnMobile" v-if="catchWarning"> Mobile warning </div>
        </div>
	</div>
</template>

<script lang="ts" setup>
import { ref, toRaw, computed, onMounted } from "vue";

import { loadScript, PayPalScriptOptions } from "@paypal/paypal-js";

import { useTokenStore, StoreItem } from "@/store/tokenStore";
import { useUserProfileStore } from "@/store/userProfileStore";
import { useActiveJobStore } from "@/store/activeJobStore";

import { close } from "ionicons/icons";
import { errorHandler, recordPaymentEvent } from "@/sdk";

import { confirm, alerta, checkForExistingUser } from "@/utility";

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

const CLIENT_ID = computed(() => {
	if ((window as any).debug_mode_active && import.meta.env.MODE == "development") {
		return import.meta.env.VITE_PP_CLIENT_ID_DEV;
	} else {
		return import.meta.env.VITE_PP_CLIENT_ID_PROD;
	}
});

const tokens = useTokenStore();
const userProfile = useUserProfileStore();
const activeJob = useActiveJobStore();

const paidFor = ref(false);
const loading = ref(false);

const paypal = ref();

const emit = defineEmits(["complete"]);

const removeItem = (item: any) => {
	//console.log("Item Index", store.items.indexOf(item), item);

	if (!item) return;

	item.quantity = 0;
};

const orderTotal = computed(() => {
	let total = 0;
	for (let iTotal = 0; iTotal < tokens.items.length; iTotal++) {
		total = total + (tokens.items[iTotal].unit_amount.value as any) * tokens.items[iTotal].quantity;
	}
	return total;
});

const totalCredits = computed(() => {
	let total = 0;
	for (let iTotal = 0; iTotal < tokens.items.length; iTotal++) {
		total = total + tokens.items[iTotal].credits * tokens.items[iTotal].quantity;
	}
	return total;
});

const itemsFiltered = computed(() => {
	return tokens.items.filter((item: StoreItem) => item.quantity > 0);
});

async function getMyOrder() {
	//NOTE: Make sure there is a user for us to assign the order to
	//NOTE: Unfortunately, this must happen BEFORE the order is placed, because we need to know what user to assign the order to
	if (!userProfile.session) {
		var accountSession = await userProfile.createTemporaryAccount();
	}

	if (!userProfile.userId) throw new Error("No Active Session");

	let myOrder = {
		purchase_units: [
			{
				reference_id: "memory-tree-credits",
				description: "Memory Tree Video Credits",

				//invoice_id: "001", //TODO: This needs to be unique by customer?
				custom_id: `${userProfile.userId}|${totalCredits.value}`,
				soft_descriptor: "Credits",
				amount: {
					currency_code: "USD",
					value: orderTotal.value,
					breakdown: {
						item_total: {
							currency_code: "USD",
							value: orderTotal.value,
						},
					},
				},
				items: toRaw(itemsFiltered.value),
			},
		],
	};

	console.log("Order", myOrder);
	return myOrder;
}

const catchWarning = ref(isMobile);

async function warnMobile() {

    if (isMobile) {
        await alerta(
                        "Tap the payment button again to continue.",
                        "PayPal on Mobile",
                        "After payment, you may need to close the page to return."
                    );

        catchWarning.value = false;
    }

}

onMounted(async () => {
	const PayPalSDKSettings = {
		"client-id": CLIENT_ID.value,
		currency: "USD",
		//"disable-funding": "credit", //NOTE: Turns off "Pay Later" option
		"enable-funding": "venmo", //NOTE: Allow Venmo payments too
	};
	console.log("PayPalSDKSettings", PayPalSDKSettings);

	const paypalSdk = await loadScript(PayPalSDKSettings as any);
	//paypalSdk.Buttons().render("#paypal-button-container");

	if (!paypalSdk) throw new Error("PayPal SDK Failed to Load");

	(paypalSdk as any)
		.Buttons({
			onClick: async (data: any, actions: any) => {
                console.log("-- POST WARNING --");
				console.log("DATA", data);
				console.log("ACTIONS", actions);


                // Disable the buttons
				//actions.enable();
                actions.resolve(data, actions);

                //this.click(data, actions);
                //.trigger("çlick");
                

			},
			createOrder: async (data: any, actions: any) => {
				const myOrder = await getMyOrder();

				if (!myOrder) throw new Error();

				console.log("DATA", data);
				console.log("ACTIONS", actions);

				console.log("order", myOrder);

				catchWarning.value = true;

				return actions.order.create(myOrder);
			},
			onApprove: (data: any, actions: any) => {
				//TODO: Process the payment ...
				paidFor.value = true;

				console.log("DATA", data);
				console.log("ACTIONS", actions);

				// This function captures the funds from the transaction.
				return actions.order
					.capture()
					.then(async (details: any) => {
						//NOTE: This function shows a transaction success message to the buyer.
						//console.log("DETAILS", details);

						//NOTE: Clear out the cart...
						tokens.resetStore();

						//TODO: trigger server payment recording
						const paymentResult = await recordPaymentEvent(details);
						console.log("PAYMENT RESULT", paymentResult);

						await userProfile.loadHistory();

						emit("complete");

						paymentMade(
							details.payer.name.given_name,
							details.payer.telephone,
							details.payer.email_address
						);
					})
					.finally(() => {
						loading.value = false; 
						catchWarning.value = true;
					});
			},
			onError: (err: any) => {
				console.log(err);
				errorHandler(err);
				catchWarning.value = true;
			},
			onInit: (data: any, actions: any) => {
				console.log("DATA", data);
				console.log("ACTIONS", actions);

				// Disable the buttons
				//actions.disable();

			},
		})
		.render(paypal.value);
});

async function paymentMade(buyerName: string, buyerPhone: string, email: string) {
	//TODO: if userProfile information is not already set, update it to reflect buyer (if possible? May fail because buyer profile already exists?)

	//NOTE: IF not a registered user, attempt to register...
	if (!userProfile.registered) {
		//TODO: if userProfile information is not already set, update it to reflect buyer (if possible? May fail because buyer profile already exists?)
		let problemUpdatingEmail = null;
		const tempPW = `Temp-${userProfile.userId}`;
		await userProfile.updateEmail(email, tempPW).catch(async (err: Error) => (problemUpdatingEmail = err));

		//NOTE: Don't really care if the following two succeed or not...
		if (buyerPhone && !userProfile.session.phone) await userProfile.updatePhone(buyerPhone, tempPW);
		if (buyerName && !userProfile.session.name) await userProfile.updateName(buyerName);

		if (problemUpdatingEmail) {
			if (await checkForExistingUser(email, problemUpdatingEmail)) {
				//No further action here...
			} else {
				errorHandler(problemUpdatingEmail);
			}
		}
	}

	if (activeJob.job) {

		//NOTE: No active job, the user was just stocking up on credits...
		await alerta(
			`If you're satisfied with the preview, click the "Generate Final Video" button to finish it.`,
			"Thank you for your purchase!",
			`Your credits are ready to use!`
		);

	} else {
		//NOTE: No active job, the user was just stocking up on credits...
		await alerta(
			`We look forward to making some great videos for you, ${buyerName}.`,
			"Thank you for your payment",
			`Your credits are ready to use!`
		);
	}
}
</script>

<style scoped>
.total-dollar {
	font-weight: bold;
	font-size: 1.2em;
}
.itemcol {
	max-width: 60%;
}
.align-text-right {
	text-align: right;
}

.individual-cost {
	color: var(--ion-color-danger);
}

.paypal-buttons { 
    position: relative;
}
.paypal-buttons .warning { 
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    /*background: rgba(222,222,222,.8);
    font-weight: 900;*/
    opacity: 0;
}

</style>
