<template>
    <!-- Fade Footer -->
    <ion-footer collapse="fade" :translucent="true">
        <ion-toolbar>
            <!-- v-if="!unregisteredWithJobStarted" -->

            <ion-buttons slot="start" class="ion-padding">
                <!-- <ion-back-button :v-show="showBackButton" slot="primary" default-href="/"></ion-back-button> -->
                <a href="https://StudioB4.com" class="footer-nav-link" target="_blank"><small>{{ vertical_name }}</small></a>
            </ion-buttons>

            <ion-buttons slot="end" class="ion-padding">
                <ion-text
                    ><small>Copyright {{ year }}</small></ion-text
                >
            </ion-buttons>
            <!-- <LanguageSelector /> -->
        </ion-toolbar>
    </ion-footer>
</template>

<script setup lang="ts">
import { menuController } from "@ionic/vue";
import { computed, ref, inject, watch } from "vue";
import { home } from "ionicons/icons";
import LanguageSelector from "@/components/LanguageSelector.vue";
import RegisterMessageComponent from "@/components/RegisterMessageComponent.vue";

import {
    menu,
    cartOutline,
    close,
    caretForwardOutline,
    saveOutline,
} from "ionicons/icons";

import { useRoute } from "vue-router";

import { useTokenStore, StoreItem } from "@/store/tokenStore";

import { useUserProfileStore } from "@/store/userProfileStore";
import { useActiveJobStore } from "@/store/activeJobStore";
import { useJobStore } from "@/store/jobStore";

const userProfile = useUserProfileStore();
const activeJob = useActiveJobStore();
const jobs = useJobStore();
const tokens = useTokenStore();

const props = defineProps({
    title: {
        type: String,
    },
    showBackButton: {
        type: Boolean,
        default: false,
    },
    supressMessageComponent: {
        type: Boolean,
        default: false,
    },
});

const vertical_name = "StudioB4.com";

const route = useRoute();

const itemsFiltered = computed(() => {
    return tokens.items.filter((item: any) => item.quantity > 0);
});

const openCart = () => {
    menuController.enable(true, "end");
    menuController.open("end");
};

const openMenu = () => {
    menuController.enable(true, "start");
    menuController.open("start");
};

menuController.enable(true, "end");
menuController.enable(true, "start");
//menuController.close("end");

const cartIsOpen = computed(() => menuEndOpen.value); // menuController.isOpen("end"));
const menuEndOpen = ref(inject("menuEndOpen", false));

const menuIsOpen = computed(() => menuStartOpen.value); // menuController.isOpen("start"));
const menuStartOpen = ref(inject("menuStartOpen", false));

const year = computed(() => {
    return new Date().getFullYear();
});

const unregisteredWithJobStarted = computed(() => {
    if (!userProfile.registered && jobs.all?.total > 0) return true;
    return false;
});

const videoTermPluralized = computed(() => {
    const plural = jobs.all?.total > 1 ? "S" : "";
    return "VIDEO" + plural;
});

const emailAddress = ref("");

const isProcessingRegistration = ref(false);

// watch(emailAddress, (newVal, oldVal) => {
//   console.log("EMAIL CHANGED", newVal);
// });

const setEmail = () => {
    //TODO: ...
    const tempPW = `Temp-${userProfile.userId}`;
    console.log("SET EMAIL", emailAddress.value);
    userProfile.updateEmail(emailAddress.value, tempPW);
};
</script>

<style scoped>
ion-button {
    font-size: 1.2em;
    font-weight: 300;
}

ion-icon {
    margin-right: 0.4em;
}

ion-toolbar {
    --background: transparent;
    border: none;
}

a.footer-nav-link { color: white; text-decoration: none;}
</style>
