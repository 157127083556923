<template>
    <ion-button @click="addItem" v-if="item?.quantity === 0">
        <ion-icon :icon="add" slot="start"></ion-icon>
        <slot>Add To Cart</slot>
        <ion-icon :icon="cartOutline" slot="end"></ion-icon>
    </ion-button>
    <ion-grid v-if="(item as any)?.quantity > 0" class="cartButtons">
        <ion-row>
            <ion-col size="3">
                <ion-button @click="addItem"><ion-icon :icon="add"></ion-icon></ion-button>
            </ion-col>
            <ion-col size="6" >
                <ion-grid class="ion-no-padding">
                    <ion-row>

                        <ion-col size="6" class="quantity">
                            {{ item?.quantity }}
                        </ion-col>
                        <ion-col size="6" class="small">
                            {{ termPacks }}<br/>
                            In <ion-icon :icon="cartOutline" alt="Cart"></ion-icon>
                        </ion-col>                                    

                    </ion-row>
                    <ion-row v-if="(item.credits > 1)">
                        <ion-col class="ion-text-center">
                            <ion-text color="medium">
                                <small>{{item.quantity * item.credits}} Credits</small>
                            </ion-text>                            
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
            <ion-col size="3" >
                <ion-button @click="removeItem"><ion-icon :icon="remove"></ion-icon></ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script lang="ts" setup>
import { defineComponent, toRaw, computed } from "vue";

import { cartOutline, add, remove } from "ionicons/icons";

import {useTokenStore, StoreItem} from "@/store/tokenStore";

const tokens = useTokenStore();

const termPacks = computed(()=> {
    const plural =  (props.item.quantity > 1 ? 's' : '')
    if (props.item.credits > 1) return "Pack" + plural;
    return "Credit" + plural;
    });

const props = defineProps({
    item: { type: Object as () => StoreItem, required: true },
    quantity: { type: Number, default: 1 },
    minimum: { type: Number, default: 1 },
});

const addItem = () => {
    let myItem: any = props.item;
    if (!myItem) throw new Error("Unknown item");
    if (!myItem.quantity) {
        myItem.quantity += props.quantity;
        return;
    }
    myItem.quantity++;
};

const removeItem = (itemSku: string) => {
    let myItem: any = props.item;
    if (!myItem) throw new Error("Unknown item");
    myItem.quantity--;
    if (myItem.quantity < props.minimum) myItem.quantity = 0;
};

const sku = computed(() => props.item.sku);
//const item = computed(() => store.items.find((item: any) => item.sku === sku.value));
</script>

<style saas scoped>
.quantity {
    font-size: 2.5em;
    line-height: 1em;
}
.small {
    font-size: .8em;
}
.vertical-align-center {
    vertical-align: middle;
}
</style>
