<template>
    <ion-menu
        side="start"
        menu-id="app-menu-start"
        content-id="main-content"
        type="push"
        @ionDidOpen="emit('opened')"
        @ionDidClose="emit('closed')"
    >
        <ion-header>
            <ion-toolbar color="primary">
                <ion-title v-t="'menu.menu'"></ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-list>
                <ion-item router-link="/home" button @click="closeMenu()">
                    <ion-icon :icon="home" color="default" slot="start"></ion-icon> {{$t('menu.home')}}
                </ion-item>
                <ion-item router-link="/gallery" button @click="closeMenu()">
                    <ion-icon :icon="imagesOutline" color="default" slot="start"></ion-icon> {{$t('menu.gallery')}}
                </ion-item>
                <ion-item router-link="/about" button @click="closeMenu()">
                    <ion-icon :icon="informationCircleOutline" color="default" slot="start"></ion-icon> {{$t('menu.about')}}
                </ion-item>
                <ion-item router-link="/faq" button @click="closeMenu()">
                    <ion-icon :icon="helpCircleOutline" color="default" slot="start"></ion-icon> {{$t('menu.faq')}}
                </ion-item>
                <ion-item router-link="/contact" button @click="closeMenu()">
                    <ion-icon :icon="helpBuoyOutline" color="default" slot="start"></ion-icon> {{$t('menu.contact')}}
                </ion-item>
                <!-- <ion-item
                    >Ideas and Uses

                    <ion-list> -->
                    <ion-item-divider></ion-item-divider>
            <!-- </ion-list>
            <ion-list> -->
                <ion-item button @click="activeJob.startJob(); closeMenu()" v-if="!activeJob.job">
                    <ion-icon :icon="addCircleOutline" color="default" slot="start"></ion-icon> {{$t('menu.start_a_new_video')}}
                </ion-item>
                <ion-item :router-link="activeJob.routeToEdit" button @click="closeMenu()" v-if="activeJob.job">
                    <ion-icon :icon="addCircleOutline" color="default" slot="start"></ion-icon> {{$t('menu.continue_editing')}}
                </ion-item>
                <ion-item router-link="/videos" button @click="closeMenu()" v-if="jobsExist">
                    <ion-icon :icon="filmOutline" color="default" slot="start"></ion-icon>
                    {{$t('menu.my_videos')}}
                    <div slot="end">
                        <ion-badge class="cart-button-badge" color="warning" v-if="jobs.unfinishedVideos.length > 0">{{ jobs.unfinishedVideos.length }}</ion-badge>
                        <ion-badge class="cart-button-badge" color="success" v-if="jobs.finishedVideos.length > 0">{{ jobs.finishedVideos.length }}</ion-badge>
                    </div>
                </ion-item>

                <ion-item-divider></ion-item-divider>
            <!-- </ion-list>
            <ion-list> -->
                <ion-item router-link="/profile" button @click="closeMenu()" v-if="userProfile.registered">
                    <ion-icon :icon="personOutline" color="default" slot="start"></ion-icon> {{$t('menu.profile')}}
                </ion-item>
                <ion-item router-link="/register" button @click="closeMenu()" v-if="!userProfile.registered">
                    <ion-icon :icon="idCardOutline" color="default" slot="start"></ion-icon> {{$t('menu.register')}}
                </ion-item>
                <ion-item router-link="/store" button @click="closeMenu()">
                    <ion-icon :icon="walletOutline" color="default" slot="start"></ion-icon>
                    {{$t('menu.credits')}}
                    <ion-badge class="credits-badge" color="danger" slot="end" v-if="userProfile.session">{{ totalCredits }}</ion-badge>
                </ion-item>
                <ion-item router-link="/login" button @click="closeMenu()" v-if="!userProfile.registered">
                    <ion-icon :icon="logInOutline" color="default" slot="start"></ion-icon> {{$t('menu.sign_in')}}
                </ion-item>
                <ion-item button @click="logout()" v-if="userProfile.registered">
                    <ion-icon :icon="logOutOutline" color="default" slot="start"></ion-icon> {{$t('menu.sign_out')}}
                </ion-item>
                <!-- <ion-item
                    >Ideas and Uses

                    <ion-list> -->
            </ion-list>
        </ion-content>
    </ion-menu>
</template>
<script lang="ts" setup>
import {computed} from "vue";
import { menuController } from '@ionic/vue';

import {
    home,
    addCircleOutline,
    informationCircleOutline,
    restaurantOutline,
    nutritionOutline,
    caretForwardCircleOutline,
    idCardOutline,
    albumsOutline,
    imagesOutline,
    logInOutline,
    walletOutline,
    logOutOutline,
    personOutline,
    helpCircleOutline,
    helpBuoyOutline,
    filmOutline
} from "ionicons/icons";

import { useActiveJobStore } from '@/store/activeJobStore';
import { useJobStore } from '@/store/jobStore';
import { useTokenStore } from "@/store/tokenStore";
import { useUserProfileStore } from "@/store/userProfileStore";

import { useIonRouter } from "@ionic/vue";

const jobs = useJobStore();
const activeJob = useActiveJobStore();
const tokens = useTokenStore();
const userProfile = useUserProfileStore();

const emit = defineEmits(["opened", "closed"]);

function closeMenu() {
    menuController.close();
}

const jobsExist = computed(() => jobs.all?.total > 0);

const totalCredits = computed(() => userProfile.credits);

const router = useIonRouter();

function logout() {
    console.log("Logout");
    userProfile.logoff().then(()=> window.location.href = "/");    
    closeMenu();    
}
</script>
<style scoped scss>

ion-badge.credits-badge {
    font-size: 1em;
}

</style>