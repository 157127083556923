// vite-plugin-vue-i18n can use the vite (rollup) mechanism to import all
// locales at once, using the special identifier
// @intlify/vite-plugin-vue-i18n/messages, as the bellow:

import { createI18n } from 'vue-i18n';

/*
 * All i18n resources specified in the plugin `include` option can be loaded
 * at once using the import syntax
 */
import messages from '@intlify/unplugin-vue-i18n/messages';

import { product } from "@/product";

// const allMessages = {
//   en: Object.assign({}, require('./locales/en.json'), require('./locales/en.fiesta-gatitos.json')),
//   //es: Object.assign({}, require('./locales/es.json'), require('./locales/tr.settings.json')),
// }


//console.log("messages", messages);
//messages[locale] = {…messages[locale], …locales(key)}

const i18n = createI18n({
  locale: 'en' + ( import.meta.env.VITE_PRODUCT ? '.' + import.meta.env.VITE_PRODUCT : ''),
  fallbackLocale: 'en',
  messages,
  preserveDirectiveContent: true
});

// export const { t, tm } = i18n.global;

export default i18n;
