<template>
    <v-tour name="myTour" :steps="steps" ></v-tour>
</template>

<script lang="ts" setup>

import { ref, onMounted, getCurrentInstance } from "vue";

// import Vue3Tour from 'vue3-tour';
// import 'vue3-tour/dist/vue3-tour.css'

const steps = [
          {
            target: '#v-step-0',  // We're using document.querySelector() under the hood
            content: `Discover <strong>Vue Tour</strong>!`
          },
          {
            target: '.v-step-1',
            content: 'An awesome plugin made with Vue.js!'
          },
          {
            target: '[data-v-step="2"]',
            content: 'Try it, you\'ll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.',
            params: {
              placement: 'top'
            }
          }
        ];

const myTour = ref();

onMounted(() => {
    const vue$ = getCurrentInstance();
    console.log("TOURS",myTour.value);
    //vue$?.$tours['myTour']?.start();
});

</script>