<template>
    <div class="ion-padding" v-bind="$attrs">

        <ion-card color="warning">
            <ion-card-content>
                <p v-t="'components.credits.part_cost'"></p>
            </ion-card-content>
        </ion-card>

        <!-- <p v-if="activeJob.isStatusBelow(JobStatus.pending)" >
            
            You need 

            <ion-badge color="danger" class="credits-badge">
                {{activeJob.creditsCost}}
            </ion-badge>

            credits to finalize your {{ activeJob.job?.title }} project

        </p> -->

        <credits-display v-if="tokens.totalCartCredits == 0 && userProfile.credits > 0" :value="userProfile.credits" color="danger">
            <slot name="start">
                <h2>You have</h2>
            </slot>
            <slot name="end">
                <p><small>available credits</small></p>    
            </slot>
        </credits-display>

        <div  v-if="activeJob.isStatusBelow(JobStatus.pending)">
            <p v-if="activeJob.creditsCost > 0 && userProfile.credits - activeJob.creditsCost >= 0">
                Which is enough to make your current video! ☺
            </p>
            <p v-if="activeJob.creditsCost == 0">
                Your current video won't cost you anything to finish! ☺
            </p>
            <p v-if="activeJob.job && userProfile.credits - activeJob.creditsCost >= 0">
                You can still stock up, and be ready to make your next video, or give as 🎁 to friends &amp; familiy.
            </p>
            <p v-if="activeJob.job && userProfile.credits - activeJob.creditsCost < 0">
                You need 
                <ion-badge color="danger" class="credits-badge">
                    {{Math.abs(userProfile.credits - activeJob.creditsCost)}}
                </ion-badge> 
                {{ userProfile.credits > 0 ? "additional" : "" }} credits 
                to finish this video.
            </p>
        </div>

        <div v-if="activeJob.job && activeJob.isStatusAbove(JobStatus.pending)">
            This video is already processing, but you can stock up on 
            credits and be ready to make your next one!
        </div>

        <p v-if="!activeJob.job">
            Stock up on credits and be ready to make your next video, 
            or give them as 🎁 to friends &amp; family.
        </p>

        <div v-if="(tokens.totalCartCredits == 0) ">
            <add-to-cart-button :item="defaultStoreItem"><div>Add To Cart<br/><small>10 Credit Pack</small></div></add-to-cart-button>
        </div>

        <p>
            <router-link to="/store" @click="$emit('closed')">Buy Credit Packs, and Save!</router-link>
        </p>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import AddToCartButton from "@/components/AddToCartButton.vue";
import CreditsDisplay from "@/components/CreditsDisplay.vue";


import { useUserProfileStore } from "@/store/userProfileStore";
import { useActiveJobStore } from "@/store/activeJobStore";

import {useTokenStore, StoreItem} from "@/store/tokenStore";

import { JobStatus } from "@/sdk";

const activeJob = useActiveJobStore();
const userProfile = useUserProfileStore();
const tokens = useTokenStore();

const emit = defineEmits(["opened", "closed"]);

const defaultStoreItem = computed(() => {
    return tokens.items.find((item:StoreItem)=>item.name == "10") as StoreItem;
});

</script>

<style scoped lang="scss">
</style>
