import {defineStore} from 'pinia';

import { Job, Part, JobStatus, databases, startNewJob, getJobById, databaseId, jobsCollectionId, partsCollectionId, getMyJobPartsList, errorHandler, removeOutput } from "@/sdk";

import { Models, Query, QueryTypes } from "appwrite";

import { useActiveJobStore } from '@/store/activeJobStore';

import {updateJob} from "@/sdk";

import { useStorage } from '@vueuse/core';

import pinia from "@/store";


const FINISHED_JOB_STATUSES = ['done', 'finalizing'] as JobStatus[];

export const useJobStore = defineStore('jobs', {
    state: () => {
        return { 
            loading: true,
            all: useStorage('jobs', { total: 0, documents: [] } as Models.DocumentList<Job>),
            autoRefreshingJobStatus: false, //Indicates the autorefresh is active
        }
    },
    actions: {
        async loadJobs() {
            console.log("LOADING JOBS");

            this.loading = true;
            //TODO: Shouldn't this be loading ALL jobs?
            //  const myJobs = await databases.listDocuments(databaseId, jobsCollectionId, [Query.equal('deleted','')])
            //      .catch(errorHandler);
            const myJobs = await databases.listDocuments(databaseId, jobsCollectionId)
               .catch(errorHandler);

            // this.all = myJobs as Models.DocumentList<Job>; 

            this.all.documents = (myJobs as Models.DocumentList<Job>).documents.filter((job: Job) => !job?.deleted);
            this.all.total = this.all.documents.length;

            console.log("LOADED JOBS", this.all);

            const activeJob = useActiveJobStore(pinia);
            if (!activeJob.job) {
                if (this.all.total == 1 && this.unfinishedVideos[0]) {
                    //NOTE: There IS an existing job, so we'll continue it...
                    await activeJob.loadJob(this.unfinishedVideos[0]);
                }
            }

            this.loading = false;
        },
        async removeJob(job: Job) {
            console.log("RemoveJob");

            this.loading = true;

            //NOTE: If the job being deleted is the active job, clear it out...
            const activeJob = useActiveJobStore(pinia);
            if (activeJob.job && activeJob.job.$id == job.$id) {
                activeJob.resetStore();
                //activeJob.$reset();            
            }

            /* const jobParts = await getMyJobPartsList(job.$id);

            jobParts.documents
                .filter((part: Part) => part.jobId == job.$id)
                .forEach( async (part: Part) => {        
                    //NOTE: No need to await here... We'll just assume success.
                    await databases.deleteDocument(databaseId, partsCollectionId, part.$id).catch(errorHandler);
                });

            //NOTE: Don't need to wait for these...
            if (job.previewOutputId) removeOutput(job.previewOutputId).catch(errorHandler);
            if (job.finalOutputId) removeOutput(job.finalOutputId).catch(errorHandler);
            
            const result = await databases.deleteDocument(databaseId, jobsCollectionId, job.$id).catch(errorHandler);
            */

            job.deleted = new Date().toISOString();
            await updateJob(job);

            //console.log("Job removed ", job.$id, result);
            console.log("Job marked as deleted ", job.$id);

            await this.loadJobs();

            this.loading = false;
        },
        async refreshJob(jobId: string) {

            console.log("RefreshJob",jobId);

            const updatedJob = await getJobById(jobId);            
            if (!updatedJob) return; //TODO: Something went wrong? Throw a tantrum?

            if (this.all.documents) {
                //NOTE: If documents list has been loaded, then update it...
                const listedJob = this.all.documents.find(job=>job.$id = jobId) as Job;            
                if (listedJob) {
                    Object.assign(listedJob, updatedJob);
                } else {
                    //TODO: Otherwise ... ??
                    this.all.documents.push(updatedJob);
                }
            }

            const activeJob = useActiveJobStore(pinia);
            if (activeJob.job && activeJob.job.$id == jobId) {
                activeJob.job = updatedJob;
            }

            return updatedJob;
            
        },
        async jobUpdated(updatedJob: Job) {

            console.log("jobUpdated",updatedJob);

            //const updatedJob = await getJobById(jobId);            
            //if (!updatedJob) return; //TODO: Something went wrong? Throw a tantrum?

            if (this.all.documents) {
                //NOTE: If documents list has been loaded, then update it...
                const listedJob = this.all.documents.find(job=>job.$id = updatedJob.$id) as Job;            
                if (listedJob) {
                    Object.assign(listedJob, updatedJob);
                } else {
                    //TODO: Otherwise ... ??
                    this.all.documents.push(updatedJob);
                }
            }

            const activeJob = useActiveJobStore(pinia);
            if (activeJob.job && activeJob.job.$id == updatedJob.$id) {
                activeJob.job = updatedJob;
            }

            return updatedJob;
            
        },
        checkJobStatus(job: Job, 
            checkWhileStatus = [JobStatus.preview, JobStatus.generating, JobStatus.pending, JobStatus.processing], 
            callback = null as any) {
            
            if (callback) callback();

            this.autoRefreshingJobStatus = true;

            this.refreshJob(job.$id)
                .then((updatedJob)=>{
                    console.log("JOB REFRESHED", updatedJob);
                    
                    const timeDiff = (new Date().getTime() - new Date(updatedJob?.$updatedAt as string).getTime()) / 1000;
                    console.log("refreshJob - Time Diff:", timeDiff);

                    if (updatedJob && 
                        (checkWhileStatus.includes(updatedJob.status) || //Status is one we're looking for ... OR
                        (timeDiff < 30 ) // Last update was less than 30 seconds ago.
                        )) {
                        console.log("SCHEDULED A FOLLOW UP CHECK");
                        setTimeout(() => this.checkJobStatus(job, checkWhileStatus, callback), 30*1000);
                    } else {
                        this.autoRefreshingJobStatus = false;
                    }
                });
        },
        resetStore() {

            console.log("***** CLEARING JOB STORE *****");

            this.loading = true,
            this.all.total = 0;
            this.all.documents = [];
            this.autoRefreshingJobStatus = false; //Indicates the autorefresh is active

            //TODO: The Piñia way ... but this doesn't work now that it's all being cached to localstore...
            //this.$reset();
        }
        // async waitAndAdd() {
        //     setTimeout(() => this.count++, 2000);
        // }
    },
    getters: { //Computed Properties for the Store
        //doubleCount: (state) => state.count * 2,        
        length: (state) => state.all?.total ?? 0,
        unfinishedVideos: (state) => state.all?.documents ? state.all.documents.filter(job => !FINISHED_JOB_STATUSES.includes(job.status)) : [],
        finishedVideos: (state) => state.all?.documents ? state.all.documents.filter(job => FINISHED_JOB_STATUSES.includes(job.status)) : []
    }
}); 
