import { ref } from 'vue';

import {defineStore, skipHydrate} from 'pinia';

import { 
    imageTypes,
    videoTypes,
    audioTypes,    
    account, 
    startTemporaryAccount, 
    getMySourcesList, 
    createFile, 
    getMyHistory,
    getStoragePreview, 
    getSourceMetaDataRecord,    
    getSourcePreview,
    getMySourcesMetadataList,
    getOutputStoragePreview, 
    errorHandler, 
    viewOutputFile,
    viewMetaPreviewFile, 
    viewSourceFile,
    downloadOutputFile,
    updateAccountPassword,
    saveMetaData,
    deleteMetadataById,
    updateMetaData,    
    sendWelcomeEmail,
    blockAccount,
    subscribeToRealTimeEvents,
    sendEmailVerification,
    //createVerification,
    createRecovery,    
    accountEmailVerified,
    updateProfileEmail, 
    updateProfilePhone, 
    updateProfileName, 
    registerAccount,
    createVerification,
    logout,
    jobsCollectionId,
    getSourceMetaPreviewImage,
    SourceMetaData,
    getPreferences,
    updatePreferences} from "@/sdk";

import { Models, OAuthProvider } from 'appwrite';

//const mySessionCache = ref();

import { HistoryEvent } from "@/sdk";

import { useJobStore } from '@/store/jobStore';   
import { useActiveJobStore } from '@/store/activeJobStore';   
import { useTokenStore } from '@/store/tokenStore';   
import router from '@/router';

import { useStorage } from '@vueuse/core';
import { alerta } from '@/utility';
import { or } from '@vuelidate/validators';

import pinia from "@/store";

export const useUserProfileStore = defineStore('userProfile', {
    state: () => {
        return { 
            loading: true,
            //sessionCache: null as any,
            session: useStorage('session', null as any),
            sources: useStorage('sources', {} as Models.FileList), 
            sourceMetadata: useStorage('source_meta', {} as Models.DocumentList<Models.Document>),
            history: useStorage('history', {} as Models.DocumentList<Models.Document>),
            swiperInstructions: useStorage('swiper_instructions', 0),
        }
    },
    actions: {
        async startup() {
            console.log("STARTING UP - Loading user profile...");

            this.session = await account.get().catch((err) => errorHandler(err,true)); //NOTE: Network only error handling

            const jobs = useJobStore(pinia);

            if (this.session) {

                console.log("FOUND ACTIVE USER SESSION", this.session);
                
                //NOTE: We're not going to wait for any of these...
                this.loadSources(); 
                this.loadHistory();
                jobs.loadJobs();

                subscribeToRealTimeEvents(this.userId);

            }

            jobs.loading = false;
            this.loading = false;

        },
        async createTemporaryAccount() {
            console.log("CREATING TEMPORARY USER ACCOUNT");
            
            this.session = await account.get().catch((err) => errorHandler(err,true)); //NOTE: Network only error handling
  
            //NOTE: If we don't already have a session, start a new one...
            if (!this.userId) {
                this.session = await startTemporaryAccount();
                
                console.log("GOT TEMPORARY SESSION", this.session);

                await this.startup(); //TODO: When and where does this fire??
            }

            // await this.loadSources();
            // await this.loadHistory();


            // subscribeToRealTimeEvents(this.userId);

            return this.session;
        },
        async close() {
            return blockAccount();
        },
        async setPreference(preferenceName: string, value: string) {
            const currentPrefs = await getPreferences();
            currentPrefs[preferenceName] = value;
            return updatePreferences(currentPrefs);
        },
        async loadSources() {
            if (!this.session) return; //TODO: Throw an error? 
            console.log("LOADING SOURCES");
            this.loading = true;
            this.sources = await getMySourcesList();


            this.sourceMetadata = await getMySourcesMetadataList(this.userId);


            console.log("LOADED SOURCES", this.sources);

            this.loading = false;
        },
        async loadHistory() {
            console.log("LOADING HISTORY");
            this.loading = true;
            //TODO: It's not clear to me why sometimes there is a userId field specifically, but other times not...
            this.history = await getMyHistory(this.userId);

            console.log("LOADED HISTORY", this.history);

            this.loading = false;
        },
        addLocalHistoryEvent(historyEvent: HistoryEvent) {
            //NOTE: Add the new meta data to our local store...            t
            const existingEntry = this.history.documents.find((historyItem) => historyItem.$id == historyEvent.$id);
            if (existingEntry) {
                Object.assign(existingEntry, historyEvent);
            }
            if (!existingEntry) {
                this.history.documents.push(historyEvent as Models.Document);
                this.history.total = this.history.total + 1;    
            }
        },

        async addFile(file: File) {
            //TODO: Add to server
            //TODO: Add part

            return await createFile(file);
        },        
        async sendWelcomeEmail() {
            return sendWelcomeEmail(this.userId);
        },
        getPreviewImage(fileId: string): string {
            if (!fileId) return ""; //NOTE: Nothing to do here
            return getSourcePreview(fileId);
        },        
        getPreviewMetaImage(fileId: string): string {
            if (!fileId) return ""; //NOTE: Nothing to do here
            return getSourceMetaPreviewImage(fileId);
        },        
        getBackupPreviewImage(fileId: string): string {
            if (!fileId) return ""; //NOTE: Nothing to do here
            return getStoragePreview(fileId);
        },        
        async saveMetadata(fileId: string, metaData: any) {
            const newMetaData = await saveMetaData(fileId, metaData);
            //NOTE: Add the new meta data to our local store...
            this.sourceMetadata.documents.push(newMetaData as Models.Document);
            this.sourceMetadata.total = this.sourceMetadata.total + 1;
            return newMetaData;
        },
        async deleteMetadataById(fileId: string) {
            const deleteResult = await deleteMetadataById(fileId);
            const existingLocalCacheEntry = this.sourceMetadata.documents.find((metadata) => metadata.$id = fileId);
            if (existingLocalCacheEntry) {
                //NOTE: Clear the local cache...
                this.sourceMetadata.documents.splice(this.sourceMetadata.documents.indexOf(existingLocalCacheEntry),1);
            }
            return true;
        },
        metadataUpdated(updatedMetadata: SourceMetaData) {
            //NOTE: This is an internal hook for the realtime subscription to update any existing record without any further server interaction...
            const existingMetadata = this.sourceMetadata.documents.find((source) => source.$id == updatedMetadata.$id);
            if (existingMetadata) {
                Object.assign(existingMetadata, updatedMetadata);
            }
            if (!existingMetadata) {
                this.sourceMetadata.documents.push(updatedMetadata);
                this.sourceMetadata.total = this.sourceMetadata.total+1;
            }
        },
        async updateMetadata(metaData: any) {
            return updateMetaData( metaData );
        },
        async getUpdatedMetadataById(fileId: string) {

            const existingMetaData = this.getSourceMetadataRecordById(fileId);

            // if (!existingMetaData) return;

            const updatedMetadata = await getSourceMetaDataRecord(fileId);
            
            if (!updatedMetadata) return;            

            if (!existingMetaData) {
                this.sourceMetadata.documents.push(updatedMetadata);
            } else {
                Object.assign(existingMetaData, updatedMetadata);
            }
            return updatedMetadata;
        },
        // async getSourceMetaDataRecord(fileId: string) {
        //     if (!fileId) return undefined; //NOTE: Nothing to do here
        //     return await getSourceMetaDataRecord(fileId);
        // },        
        getSourceMetaDataFile(fileId: string) {
            if (!fileId) return undefined; //NOTE: Nothing to do here
            return viewMetaPreviewFile(fileId);
        },        


        getSourceFile(fileId: string): string {
            if (!fileId) return ""; //NOTE: Nothing to do here
            return viewSourceFile(fileId);
        },                
        getSourceFileRecordById(fileId: string) : Models.File | undefined { 
            if (!fileId) return; //NOTE: Nothing to do here
            if (!this.sources.total) return; //NOTE: Nothing to do here -- We don't have any metaData
            return this.sources.files.find((source: Models.File) => source.$id == fileId);
        },                
        getSourceMetadataRecordById(fileId: string) : SourceMetaData | undefined { 
            if (!fileId) return; //NOTE: Nothing to do here
            if (!this.sourceMetadata.total) return; //NOTE: Nothing to do here -- We don't have any metaData
            return this.sourceMetadata.documents.find((sourceMetadata: Models.Document) => sourceMetadata.$id == fileId) as SourceMetaData | undefined;
        },                
        getMetadataPreviewImage(fileId: string): string {
            if (!fileId) return ""; //NOTE: Nothing to do here
            return getSourceMetaPreviewImage(fileId);
        },        

        
        getMetadataPreviewFile(fileId: string): string {
            if (!fileId) return ""; //NOTE: Nothing to do here
            return viewMetaPreviewFile(fileId);
        },                



        getOutputPreviewImage(fileId: string): string {
            if (!fileId) return ""; //NOTE: Nothing to do here
            return getOutputStoragePreview(fileId);
        },        
        getOutputFile(fileId: string): string {
            if (!fileId) return ""; //NOTE: Nothing to do here
            return viewOutputFile(fileId);
        },                
        getOutputFileDownload(fileId: string): string {
            if (!fileId) return ""; //NOTE: Nothing to do here
            return downloadOutputFile(fileId);
        },                
        // async waitAndAdd() {
        //     setTimeout(() => this.count++, 2000);
        // }

        /*ACCOUNT*/
        async updateEmail(email:string, password: string) {
            const updatedEmail = await updateProfileEmail(email, password);

            if (updatedEmail) {
                this.session.email = updatedEmail.email;
                this.verifyEmail(); //'https://memory-tree.studiob4.com/verify');
            }

            return updatedEmail;
        },
        async verifyEmail() {
            if (!this.session.emailVerification && this.session.email) {
                console.log("Verifying email address...")
                //TODO: Kick off the first e-mail registration event

                //TODO: Verification Code / Link
                //TODO: This is the Appwrite way, which seems broken, or e-mail isn't working anyway... we'll use our own system for now...
                //const verification = await createVerification('https://memory-tree.studiob4.com/verify');
                //console.log("VERIFICATION", verification);

                //TODO: Kick off "Welcome" e-mail with 2 Credit Bonus
                await sendEmailVerification(this.session?.userId ?? this.session.$id, this.session.email);
                //await alerta("Verify your e-mail by clicking the link inside, and we'll give you 3 FREE credits!", "Verify E-Mail Address", "We sent you an e-mail.");

            }
        },
        async verifiedEmail() {
            if (!this.session.emailVerification && this.session.email) {
                console.log("Customer verified email address...")
                //TODO: Kick off the first e-mail registration event

                //TODO: Verification Code / Link
                //TODO: This is the Appwrite way, which seems broken, or e-mail isn't working anyway... we'll use our own system for now...
                //const verification = await createVerification('https://memory-tree.studiob4.com/verify');
                //console.log("VERIFICATION", verification);
                return await accountEmailVerified(this.userId);

                //TODO: Kick off "Welcome" e-mail with 2 Credit Bonus
                //await sendEmailVerifiedNotice(this.session?.userId ?? this.session.$id, this.session.email);
                //await alerta("Verify your e-mail by clicking the link inside, and we'll give you 3 FREE credits!", "Verify E-Mail Address", "We sent you an e-mail.");

            }
        },
        updatePhone(phone:string, password: string) {
            return updateProfilePhone(phone, password);
        },
        updateName(name:string) {
            return updateProfileName(name);
        },
        async login(email: string, password: string) {
            const session = await account.createEmailPasswordSession(email,password).catch(errorHandler);
            if (session)
                await this.startup();
            return session;
        },
        async oAuthLogin(provider:OAuthProvider) {
            var url = window.location.href;
            const session = account.createOAuth2Session(provider, url, url.replace('login','home'));
            if (session)
                this.startup();
            return session;
        },
        async logoff() {
            const logoutResult = await logout();
            this.resetAll();            
            return logoutResult;
        },
        async register(email: string, password: string) {
            //TODO: If the new e-mail address for a starter account matches an existing e-mail address, ask to combine account. 
            
            let result;

            //TODO: If there's not an active session, this will be a straight registration...
            if (!this.session) {
                result = await registerAccount(email, password);
                    //.catch();
            } else if (!this.registered) {
                result = await this.updateEmail(email, password);
                    //.catch();
            } else {
                //TODO: There is already a session, and the user already seems to be registered ... so ...
            }
            //TODO: Register the account. Combine accounts if/when necessary...

            return result;
            
        },
        async setPassword(newPassword: string, oldPassword: string) {

            const updated = await updateAccountPassword(newPassword, oldPassword);

            return updated;

        },
        async resetPassword(email: string) {

            const recovery = await createRecovery(email).catch(errorHandler);


        },


        /* UTILITY */
        resetAll() {
            //TODO: Isn't there a better way to do this (a Pinia way?)
            // this.session = null;
            // this.credits = 0;
            // this.sources = {} as Models.FileList;
            // this.history = {} as Models.DocumentList<Models.Document>;

            // this.session = undefined;
            // this.sources.total = 0;
            // this.sources.files = [];
            // this.sourceMetadata.total = 0;
            // this.sourceMetadata.documents = [];
            // this.history.total = 0;
            // this.history.documents = [];
            // this.swiperInstructions = 0;
            this.session = null as any;
            this.sources = {} as Models.FileList;
            this.sourceMetadata = {} as Models.DocumentList<Models.Document>;
            this.history = {} as Models.DocumentList<Models.Document>;
            this.swiperInstructions = 0;
            console.log("UserProfile Reset", this.$state);



            //TODO: The Piñia way ... but this doesn't work now that it's all being cached to localstore...
//            this.$reset();

            //TODO: If user profile changes, ALL other stores should be reset too
            useJobStore(pinia).resetStore();            
            useActiveJobStore(pinia).resetStore();
            useTokenStore(pinia).resetStore();

        },
        getStorageSize(list: Models.File[]) {
            if (!list) return;
            return Math.trunc(list.reduce((previousValue, file) => previousValue + file.sizeOriginal, 0) / (1024 * 1024));
        } ,
    },
    getters: { //Computed Properties for the Store
        //doubleCount: (state) => state.count * 2,
        //session: async (state) => await account.get()
        userId: (state) => { if (state.session?.$id) return state.session?.userId ? state.session.userId : state.session.$id; },
        audioSources: (state) => state.sources?.files && state.sources?.files.filter((file) => (Object.values(audioTypes).includes(file.mimeType))),
        imageAndVideoSources: (state) => state.sources?.files && state.sources?.files.filter((file) => (Object.values(imageTypes).includes(file.mimeType) || Object.values(videoTypes).includes(file.mimeType))),        
        registered: (state) => state.session?.email,
        confirmed: (state) => state.session?.emailVerification,
        credits: (state) => (state.history?.documents && state.history.documents.reduce( (previousValue: Number, creditEvent: Models.Document) => previousValue + creditEvent.credit - creditEvent.debit, 0)) ?? 0,
        isLoggedIn(): boolean { return this.session != null},
        isTemporaryUser(): boolean { return this.session != null  && !this.session?.email},
    }
});

